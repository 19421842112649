.htk3-welcome_container {
  box-shadow: inset 0px 90vh 20px #00000031;

  background-image: url("../assets/img/img-2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100svh;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;
}
.htk3-welcome_container-title_container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;
  padding: 0 2rem;
}
.htk3-welcome_container-title_container h2 {
  font-size: 1.4rem;
  letter-spacing: 0.4rem;
  color: #ccc;
  text-align: start;

  animation: from-right 1s forwards;
  animation-delay: 0.5s;
  opacity: 0;
}

.htk3-welcome_container-title_container h1 {
  font-size: 2.2rem;
  letter-spacing: 0.4rem;
  line-height: 2.6rem;
  text-transform: uppercase;
  text-align: start;
  color: #fff;

  animation: from-left 1s forwards;
  animation-delay: 1s;
  opacity: 0;
}

.htk3-welcome_container-button_container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2rem;
  padding: 0 2rem;

  opacity: 0.9;
}
.htk3-welcome_container-button_container button {
  width: 100%;
  padding: 1rem 2rem;
  font-size: 1.4rem;
  border-radius: 10rem;
  text-transform: uppercase;

  background: var(--color-htk-2);
  border: 2px solid var(--color-htk-2);

  animation: fade-in 2s forwards;
  animation-delay: 1s;
  opacity: 0;

  &:hover {
    background: #fff;
    color: var(--color-htk-2);
  }
}

#htk3-welcome_container-service-button {
  background: #fff;
  color: var(--color-htk-2);
  border: 2px solid var(--color-htk-2);

  &:hover {
    background: var(--color-htk-2);
    color: #fff;
  }
}

.htk3-welcome_container-video_container {
  max-width: 100%;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  margin-top: 8svh;

  border-radius: 0;
  overflow: hidden;

  animation: fade-in 1s forwards;
  animation-delay: 1.5s;
  opacity: 0;
}

.htk3-welcome_container-video_container video {
  min-height: 100svh;
  max-width: 100%;
  border-radius: 0;
}

@keyframes from-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes from-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-in {
  100% {
    opacity: 1;
  }
}

/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .htk3-welcome_container-video_container {
    margin-top: 35svh;
  }

  .htk3-welcome_container-video_container video {
    min-height: 500px;
    max-height: 500px;
    min-width: 500px;
    max-width: 500px;
    border-radius: 100%;
    object-fit: cover;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .htk3-welcome_container {
    box-shadow: inset 100vw 0px 100px #0000003d;
    gap: 0;
    justify-content: center;
    align-items: center;
  }

  .htk3-welcome_container-title_container {
    padding-top: 4rem;
    gap: 0;
  }

  .htk3-welcome_container-title_container h1 {
    text-transform: uppercase;
    font-size: 6rem;
    font-weight: 700;
    letter-spacing: 1.2rem;
    line-height: 6rem;
  }

  .htk3-welcome_container-title_container h2 {
    font-size: 4.2rem;
    font-weight: 700;
    letter-spacing: 0.6rem;
  }

  .htk3-welcome_container-button_container {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8rem;
    padding-right: 4rem;
  }

  .htk3-welcome_container-button_container button {
    font-size: 1.6rem;
    padding: 1.4rem 4rem;
  }

  .htk3-welcome_container-video_container {
    left: 45%;
    top: 45%;
    margin: 0;
  }
}
