.htk3-desc_container {
}
.htk3-desc_container-content {
}
.htk3-desc_container-first {
  background: #fff;
  padding: 0 2rem;
}

.htk3-desc_container-first img {
  aspect-ratio: 1/1;
  object-fit: contain;
  width: 100%;
  border-radius: 0.2rem;
}

.htk3-desc_container-first p {
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: var(--color-htk-3);
  padding: 1rem 0;
  text-align: center;
}
.htk3-desc_container-second {
  background: var(--color-htk-2);
}

.htk3-desc_container-second p {
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: #fff;
  padding: 2rem;
  text-align: center;
}

.htk3-desc_container-second video {
  aspect-ratio: 1/1;
  object-fit: cover;
  width: 100%;
  border-radius: 0.2rem;
}

.htk3-desc_container-second button {
  margin-bottom: 2rem;
  padding: 1rem 2rem;
  border-radius: 2rem;
  min-width: 240px;

  font-size: 1rem;
  text-transform: uppercase;
  background: transparent;
  border: 2px solid #fff;
}

/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .htk3-desc_container {
    flex-direction: column;
    padding: 4rem;
    background: linear-gradient(75deg, #fff 1017px, #3265cc21 0);
  }

  .htk3-desc_container-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .htk3-desc_container-first {
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60svh;
    border-radius: 100rem;
    padding-top: 4rem;
  }

  .htk3-desc_container-first img {
    width: 50%;
    aspect-ratio: 2/1;
    padding: 4rem 0;
  }

  .htk3-desc_container-first-desc {
  }

  .htk3-desc_container-first-desc p {
    font-size: 1.6rem;
    line-height: 1.8rem;
    color: #333;
    text-align: start;
  }

  .htk3-desc_container-second {
    background: transparent;
    width: 50%;
  }

  .htk3-desc_container-second video {
    aspect-ratio: 2/1;
    margin: 8rem 0;
    margin-bottom: 0rem;
  }

  .htk3-desc_container-second-desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .htk3-desc_container-second-desc p {
    font-size: 1.6rem;
    line-height: 1.8rem;
    color: #333;
  }

  .htk3-desc_container-second button {
    text-transform: uppercase;
    font-size: 1.8rem;
    background: var(--color-htk-2);
    color: #fff;
    border: 2px solid #3265cc21;
    margin-top: 4rem;
    opacity: 1;
    &:hover {
      background: #fff;
      color: var(--color-htk-2);
    }
  }

  #htk3-desc_container-highlight {
    font-weight: 700;
    color: var(--color-htk-2);
  }
}
