.js11_container {
  min-height: 100svh;
  max-height: 100svh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  padding: 25%;
  background-color: #000;

  z-index: 1;
  position: relative;
}
.js11_container img {
  width: 100%;
  height: 50svh;
  border-radius: 1rem;
  object-fit: cover;
}
.js11_container h1 {
  font-size: 4rem;
  color: #fff;
}

.js11_container p {
  font-size: 2rem;
  color: #fff;
}

.js11_container button {
  font-size: 2rem;
  padding: 1rem 2rem;
  border-radius: 1rem;
  cursor: pointer;
  background: #fff;
  transition: 0.3s;
  margin-top: 1rem;

  &:hover {
    background: orange;
  }
}

@media screen and (max-width: 600px) {
  .js11_container {
    padding: 2rem;
  }
  .js11_container h1 {
    font-size: 1.6rem;
  }

  .js11_container p {
    font-size: 1rem;
    line-height: 1.2rem;
  }

  .js11_container button {
    font-size: 1.6rem;
    padding: 0.6rem 1.2rem;
    border-radius: 0.4rem;
  }
}
