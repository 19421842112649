.bygg1hero {
  position: relative;
  min-height: 100svh;
  max-height: 100svh;
  background-image: url("../../assets/images/house-area.jpg");
  background-size: cover;
  background-position: bottom;

  /* filter: grayscale(0.6); */
  box-shadow: inset 0 100rem 100rem #00000060;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  padding: 8rem 0;
}

.bygg1hero-text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  flex: 4;

  width: 75%;
}

.bygg1hero-text h1 {
  color: #fff;
  font-size: 3rem;
  font-weight: 300;
}
.bygg1hero-text p {
  color: #fff;
  font-size: 1.8rem;
  font-weight: 300;
  padding-bottom: 4rem;
}

.bygg1hero-actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 4rem; */

  z-index: 1;
  /* background: #00000060; */
}

.bygg1hero-action {
  padding-left: 28rem;
}

.bygg1hero-action button {
  border-radius: 100rem;
  background: #2c515e;
  text-transform: uppercase;

  &:hover {
    color: #fff;
  }
}

.bygg1hero-icons {
  display: flex;
  color: #fff;

  /* transform: scale(2); */
  /* gap: 10rem; */
  /* background: #ffffff80; */
  gap: 4rem;
  padding-right: 15rem;
}

.bygg1hero-icon {
  background: #2c515ede;
  border-radius: 100rem;
  padding: 1.4rem;
  width: 80px;
  height: 80px;
}

.bygg1hero-wave {
  position: absolute;
  bottom: -2rem;

  /* transform: rotateY(180deg); */
}

@media screen and (max-width: 560px) {
  .bygg1hero {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    padding: 0;
  }

  .bygg1hero-text {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    padding-top: 6rem;

    flex: 4;

    width: 90%;
  }

  .bygg1hero-text h1 {
    color: #fff;
    font-size: 1.6rem;
    font-weight: 300;
  }
  .bygg1hero-text p {
    color: #fff;
    font-size: 1rem;
    font-weight: 300;
    padding-bottom: 0;
  }

  .bygg1hero-actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* padding: 0 4rem; */

    z-index: 1;
  }

  .bygg1hero-action {
    padding-left: 0;
  }

  .bygg1hero-action button {
    font-size: 1rem;
    padding: 1rem 2rem;
    margin-bottom: 2rem;
  }

  .bygg1hero-icons {
    display: flex;
    color: #fff;

    /* transform: scale(2); */
    /* gap: 10rem; */
    /* background: #ffffff80; */
    gap: 3rem;
    padding-right: 0;
  }

  .bygg1hero-icon {
    background: #2c515ede;
    border-radius: 100rem;
    padding: 1rem;
    width: 60px;
    height: 60px;
  }

  .bygg1hero-wave {
    position: absolute;
    bottom: 0rem;

    /* transform: rotateY(180deg); */
  }
}
