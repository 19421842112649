.htk3-app_container {
  /* border: 1px solid black; */

  max-width: 100%;
  min-height: 100vh;

  background: #fff;
}

.htk3-app_container button {
  border-radius: 100px;
  opacity: 1;
}
