.energi1section {
  min-height: 100svh;
  /* background: #000000c0; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.energi1section-content {
  background: #8369534d;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 10rem 0;
  padding-right: 20%;
}

.energi1section-content h1 {
  color: #f87104;
  color: #333;
  font-size: 2.6rem;
  font-weight: 300;
  text-align: start;

  animation: fade-in-text;
  animation-timeline: view();
}

.energi1section-content-text {
  flex: 1;
  /* background: #333; */
  min-height: 40svh;
  padding: 4rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  animation: fade-in-text;
  animation-timeline: view();

  transform: translateX(30%);
}

.energi1section-content-text h1 {
  color: #0c0f27;
  text-align: start;
  font-size: 3.4rem;
  font-weight: 500;
  padding-bottom: 2rem;
}
.energi1section-content-text p {
  color: #0c0f27;
  text-align: start;
  font-size: 1.4rem;
  font-weight: 300;
  padding-top: 2rem;
  width: 65%;
}

.energi1section-content-text h3 {
  color: #0c0f27;
  text-align: start;
  font-size: 1.6rem;
  font-weight: 400;
  padding-top: 2rem;
  width: 65%;
}

.energi1section-content-reversed {
  background: #83695398;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 10rem 0;
  padding-left: 20%;
}

.energi1section-content-text-reversed {
  flex: 1;
  /* background: #333; */
  min-height: 40svh;
  padding: 4rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;

  animation: fade-in-text;
  animation-timeline: view();

  transform: translateX(-30%);
}

.energi1section-content-text-reversed h1 {
  color: #0c0f27;
  text-align: start;
  font-size: 3.4rem;
  font-weight: 500;
  padding-bottom: 2rem;
}
.energi1section-content-text-reversed p {
  color: #0c0f27;
  text-align: end;
  font-size: 1.4rem;
  font-weight: 300;
  padding-top: 2rem;
  width: 65%;
}

.energi1section-content-text-reversed h3 {
  color: #0c0f27;
  text-align: end;
  font-size: 1.6rem;
  font-weight: 400;
  padding-top: 2rem;
  width: 65%;
}

@keyframes fade-in-text {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.energi1section-content-text-underline {
  width: 100%;
  height: 3px;
  background: #ffab11;
}

.energi1section-content-image {
  flex: 1;
}

.energi1section-content-image img {
  width: 100%;
  height: 80svh;
  aspect-ratio: 1/2;
  object-fit: cover;

  border-radius: 2rem;

  border: 1px solid #ffac11;
  box-shadow: 0 0 6rem #ffac11a4;
  box-shadow: 0 0 4rem #0c0f2781;
}

@media screen and (max-width: 560px) {
  .energi1section {
    min-height: 100svh;
    /* background: #000000c0; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .energi1section-content {
    background: #8369534d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    padding: 0;
    padding-bottom: 2rem;
  }

  .energi1section-content h1 {
    color: #f87104;
    color: #333;
    font-size: 1.6rem;
    font-weight: 300;
    text-align: start;
  }

  .energi1section-content-text {
    flex: 1;
    /* background: #333; */
    min-height: 40svh;
    padding: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transform: translateX(0);
  }

  .energi1section-content-text h1 {
    color: #0c0f27;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 500;
    padding: 2rem 0;
  }
  .energi1section-content-text p {
    color: #0c0f27;
    text-align: center;
    font-size: 1rem;
    font-weight: 300;
    width: 100%;
  }

  .energi1section-content-text h3 {
    color: #0c0f27;
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    width: 100%;
  }

  .energi1section-content-text-underline {
    width: 100%;
    height: 3px;
    background: #ffab11;
  }

  .energi1section-content-image {
    flex: 1;
  }

  .energi1section-content-image img {
    width: 90%;
    height: auto;
    aspect-ratio: 1/1;
    object-fit: cover;

    border-radius: 2rem;

    border: 1px solid #ffac11;
    box-shadow: none;
  }
}
