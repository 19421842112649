.napra3-container {
  min-height: 100vh;
  max-height: 100vh;
  background-image: url("../naprapatrehab/naprapatrehab_img/DSC06345.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: hue(1);

  box-shadow: inset -100vw 0px 100px #00000079;
}

@media screen and (max-width: 800px) {
  .napra3-container {
    box-shadow: inset 0px 100vw 90vh #00000079;
  }
}
