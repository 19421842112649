.js7_container * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.js7_container {
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.js7_container-timer_container {
  width: 50%;
  height: 50svh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.js7_container-timer_container-clock_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.js7_container-timer_container-clock_container p {
  font-size: 3rem;
  color: #333;
}

.js7_container-timer_container-buttons_container {
  /* border: 1px solid black; */
}

.js7_container-timer_container-buttons_container button {
  margin: 1rem;
  padding: 1rem 4rem;
  border: none;
  border-radius: 0.2rem;
  color: #fff;
  font-size: 1.6rem;
  opacity: 0.6;
  cursor: pointer;
  box-shadow: 2px 2px #fff;

  transition: 0.5s;
  min-width: 260px;

  &:hover {
    opacity: 1;
    box-shadow: 2px 2px #aaa;
  }
}

#button-start {
  background: green;
}
#button-stop {
  background: red;
}
#button-reset {
  background: gray;
}

@media screen and (max-width: 600px) {
  .js7_container h1 {
    font-size: 2rem;
    margin: 2rem;
  }
}
