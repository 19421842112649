@import url("https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap");

.challenge_container {
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 2rem;
}

.challenge__container {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 2rem;
}

.challenge {
  border-radius: 6px;
  width: 25rem;
  height: 25rem;

  /* background: linear-gradient(45deg, #43389F, #4ec6ca  ); */
  background: #3faf82;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bar-container {
  /* border: 1px solid black; */
  width: 75px;
  height: 50px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bar-container:hover {
  cursor: pointer;
}

.bar-container:active {
  /* transform: rotate(90deg); */
  animation: toggle 3s timing-function delay iteration-count direction fill-mode;
  animation: toggle 1s;
}

@keyframes toggle {
  0% {
    height: 50px;
  }

  100% {
    height: 8px;
  }
}

.bar {
  width: 100%;
  height: 8px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 10px #555;
}
