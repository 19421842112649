.button-orange {
    /* background: orange; */
    font-size: 22px;
    
    cursor: pointer;
    opacity: 0.8;
    border: none;
    font-family: 'League Spartan', 'sans-serif', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    
    background: orange;
    color: #fff;

    padding: 8px 12px;
    border-radius: 4px;
    /* border: 1px solid black; */
}

.button-orange:hover {
    opacity: 1;
}