.htk3-about_container {
  min-height: 100svh;
  max-height: 100svh;
  box-sizing: border-box;

  background: linear-gradient(75deg, #fff 1725.5px, #3265cc21 1481.5px);
  /* background: #3265cc21; */

  display: flex;
  flex-direction: column;
  padding: 0 8rem;
}

.htk3-about_container-content {
  display: flex;
  /* border-radius: 0.2rem; */
  /* box-shadow: 0 0 2rem #999; */
  overflow: hidden;
}

.htk3-about_container-content-text {
  background: var(--color-htk-2);
  height: 80svh;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 2rem;
}

.htk3-about_container-content-text p {
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #fff;
  text-align: start;
  padding: 2rem;
}

.htk3-about_container-content-text ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}

.htk3-about_container-content-text li {
  list-style: none;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  color: #fff;
  font-size: 1.6rem;
}

.htk3-about_container-content-image {
  height: 80svh;
  width: 60%;
  /* padding: 2rem;
  margin: 4rem; */
}

.htk3-about_container-content-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 560px) {
  .htk3-about_container {
    flex-direction: column;

    min-height: 100svh;
    max-height: 100%;
    background: #fff;

    padding: 0;
  }

  .htk3-about_container-content {
    min-height: 100svh;
    max-height: 100%;
    display: flex;
    flex-direction: column;

    position: static;
  }

  .htk3-about_container-content-text {
    background: #fff;
    /* height: 60svh; */
    width: 100%;
    min-height: 100svh;
    max-height: 100%;

    position: static;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    padding: 0;
  }

  .htk3-about_container-content-text p {
    font-size: 1.3rem;
    line-height: 1.6rem;
    color: #333;
    text-align: center;
    /* margin-bottom: 2rem; */
    padding: 1rem 2rem;

    /* border-radius: 2rem; */
  }

  .htk3-about_container-content-image {
    position: static;

    height: 50svh;
    width: 100%;
  }

  .htk3-about_container-content-image img {
    padding: 0;
  }

  .htk3-about_container-content-text ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  .htk3-about_container-content-text li {
    width: 100%;
    color: #000;
    font-size: 1.2rem;
    margin-top: 1rem;

    /* font-weight: 700; */
  }
}
