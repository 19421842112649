.resume-container {
  /* background: #fafafa; */
  background: #fafafa;
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.about-container {
  /* border: #333 solid 2px; */
  border-radius: 25px;
  margin: 32px;
  flex: 1;
}

.experience-container {
  border: #333 solid 3px;
  background: #fff;
  border-radius: 4px;
  margin: 32px;
  padding-left: 32px;
  padding-right: 32px;
  flex: 3;
}

.experience-title {
  font-family: Arial, Helvetica, sans-serif;
  color: #4682b4;
  font-weight: bold;
}

.avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 32px;
}

.avatar {
  width: 160px;
  border-radius: 100px;
}

.resume_info-container {
  padding: 32px;
}

.info-content {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin-bottom: 12px;
}

.info-label {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.info-text {
  font-size: 17px;
  color: #696969;
}
.skills-resume-container {
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 32px;
}
.skills-text {
  color: #333;
  margin-right: 12px;
}

@media only screen and (max-width: 720px) {
  .experience-container {
    border: none;
    border-top: #333 solid 3px;
    border-bottom: #333 solid 3px;
    background: #fff;
    border-radius: 0;
    margin: 0;
    padding-left: 32px;
    padding-right: 32px;
    flex: 3;
  }
}
