.napra3-about-container {
  /* border: 1px solid black; */
  background: #fff;
  /* border-top: 2px solid #999; */

  /* min-height: 100vh; */

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 6rem; */
}

.napra3-about-container-desc {
  padding: 0 8rem;
  display: flex;
  gap: 2rem;
}

.napra3-about-container-desc h3 {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  /* font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif; */
  font-size: 2.2rem;
  font-weight: 700;
  text-align: start;
  flex: 1;
  padding-right: 2rem;
}

.napra3-about-container-desc p {
  /* font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif; */
  font-size: 1.8rem;
  font-weight: 300;
  text-align: start;
  letter-spacing: 0.2rem;
  flex: 2;
}

.napra3-about-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4rem 8rem;
}
.napra3-about-header h1 {
  min-width: 400px;
  font-size: 1.6rem;
  font-weight: 300;
  letter-spacing: 0.4rem;
  padding-top: 5px;
}
.napra3-about-header-line {
  height: 16px;
  width: 100%;
  background: var(--color-napra);
}

.napra3-about-container_services-container {
  /* border: 1px solid black; */
  width: 100%;

  display: flex;
  gap: 4rem;
  padding: 4rem 8rem;
}

.napra3-about-container_services-container_service-card-container {
  /* border: 1px solid #333; */

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* border: 1px solid black; */
  flex: 1;
}

.napra3-about-container_services-container_service-card {
  width: 100%;
  aspect-ratio: 2/1;
  object-fit: cover;
}

#napra3-about-container_services-container_service-card-1 {
  background-image: url("../../naprapatrehab/naprapatrehab_img/DSC06469.jpeg");
  background-size: cover;
  background-position: center;
}
#napra3-about-container_services-container_service-card-2 {
  background-image: url("../../naprapatrehab/naprapatrehab_img/DSC06359___serialized1.jpeg");
  background-size: cover;
  background-position: bottom;
}
#napra3-about-container_services-container_service-card-3 {
  background-image: url("../../naprapatrehab/naprapatrehab_img/DSC06368.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.napra3-about-container_services-container_service-card_desc-container {
  background: var(--color-napra);
  width: 100%;
  height: 100%;
  padding: 2.8rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.napra3-about-container_services-container_service-card_desc-container h3 {
  color: #fff;
  font-size: 2.2rem;
  font-weight: 500;
  text-align: start;
  padding-bottom: 1rem;
}

.napra3-about-container_services-container_service-card_desc-container p {
  color: #fff;
  font-size: 1.8rem;
  font-weight: 300;
  text-align: start;
}

.napra3-about-container_services-container_service-card h3 {
  font-family: "League Spartan", "sans-serif", "Lucida Sans",
    "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode";
  color: #fff;
  text-shadow: 0px 0px 2px #000;
}
.napra3-about-container_services-container_service-card p {
  text-align: center;
  font-size: 1.3rem;
  font-weight: 700;
  color: #fff;
  text-shadow: 0px 0px 2px #000;
}

.napra3-about-container_desc-carousel-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 6rem; */

  /* background: #fff; */
  border-radius: 6rem;
}

.napra3-about-container_desc-container {
  /* border: 1px solid black; */
  width: 50vw;
  /* padding: 4rem; */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
}

.napra3-about-container_desc-container_title {
  font-family: "League Spartan", "sans-serif", "Lucida Sans",
    "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode";
  /* color: orange; */
  color: var(--color-napra);
  font-weight: 500;
}

.napra3-about-container_desc-container_text {
  font-size: 1.8rem;
  font-weight: 300;
  text-align: start;
}

.napra3-about-container_carousel-container {
  width: 500px;
  margin: 6rem;
}

.napra3-about-container_carousel-container_img {
  /* border-radius: 6rem; */

  max-height: 330px;
}

@media only screen and (max-width: 800px) {
  .napra3-about-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }

  .napra3-about-container-desc {
    padding: 0rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .napra3-about-container-desc h3 {
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    /* font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif; */
    font-size: 1.6rem;
    font-weight: 700;
    padding-right: 0;
    padding-top: 1rem;
  }

  .napra3-about-container-desc p {
    /* font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif; */
    font-size: 1.2rem;
    font-weight: 300;
    letter-spacing: 0.1rem;
    padding-bottom: 2rem;
  }

  .napra3-about-header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0;
  }
  .napra3-about-header h1 {
    min-width: 200px;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 0.4rem;
    padding-top: 5px;
  }
  .napra3-about-header-line {
    height: 16px;
    width: 100%;
    background: var(--color-napra);
  }

  .napra3-about-container_services-container {
    /* border: 1px solid black; */
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0;
  }

  .napra3-about-container_services-container_service-card-container {
    /* border: 1px solid #333; */

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* border: 1px solid black; */
    flex: 1;
    border-radius: 0.2rem;
    overflow: hidden;
  }

  .napra3-about-container_services-container_service-card {
    width: 100%;
    aspect-ratio: 2/1;
    object-fit: cover;
  }

  #napra3-about-container_services-container_service-card-1 {
    background-image: url("../../naprapatrehab/naprapatrehab_img/DSC06469.jpeg");
    background-size: cover;
    background-position: center;
  }
  #napra3-about-container_services-container_service-card-2 {
    background-image: url("../../naprapatrehab/naprapatrehab_img/DSC06359___serialized1.jpeg");
    background-size: cover;
    background-position: bottom;
  }
  #napra3-about-container_services-container_service-card-3 {
    background-image: url("../../naprapatrehab/naprapatrehab_img/DSC06368.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .napra3-about-container_services-container_service-card_desc-container {
    background: var(--color-napra);
    width: 100%;
    height: 100%;
    padding: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .napra3-about-container_services-container_service-card_desc-container h3 {
    color: #fff;
    font-size: 1.6rem;
    font-weight: 500;
    text-align: start;
    padding-bottom: 1rem;
  }

  .napra3-about-container_services-container_service-card_desc-container p {
    color: #fff;
    font-size: 1.2rem;
    font-weight: 300;
    text-align: start;
  }

  .napra3-about-container_services-container_service-card h3 {
    font-family: "League Spartan", "sans-serif", "Lucida Sans",
      "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode";
    color: #fff;
    text-shadow: 0px 0px 2px #000;
  }
  .napra3-about-container_services-container_service-card p {
    text-align: center;
    font-size: 1.3rem;
    font-weight: 700;
    color: #fff;
    text-shadow: 0px 0px 2px #000;
  }

  .napra3-about-container_desc-carousel-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .napra3-about-container_desc-container {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    padding: 1rem;
  }

  .napra3-about-container_desc-container_title {
    font-family: "League Spartan", "sans-serif", "Lucida Sans",
      "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode";
    /* color: orange; */
    color: var(--color-napra);
    font-weight: 500;
    font-size: 1.6rem;
    text-align: start;
  }

  .napra3-about-container_desc-container_text {
    font-size: 1.2rem;
    font-weight: 300;
    text-align: start;
  }

  .napra3-about-container_carousel-container {
    width: 100%;
    margin: 0;
  }

  .napra3-about-container_carousel-container_img {
    /* border-radius: 6rem; */

    max-height: 330px;
  }
}

/* Carousel CSS */

li.slide.selected {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 16px #555;
  /* border: 2px solid #aaa; */
}

ul.thumbs.animated {
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

p.carousel-status {
  display: none;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow:hover {
  background: none;
}
