.js3-container-slider {
  width: 65svw;
  min-height: 45svh;
  max-height: 100svh;
  border-radius: 1.8rem;
  box-shadow: 0 2px 16px #0000009f;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;

  padding: 8rem 4rem;
}

.js3-container-slider img {
  object-fit: cover;
  width: 200px;
  height: 200px;
  border-radius: 100%;

  position: absolute;
  top: -25%;
  left: 50%;
  transform: translateX(-50%);

  image-resolution: 1dpi snap;
}
.js3-container-slider h3 {
  color: #fff;
}
.js3-container-slider p {
  color: #fff;
  font-size: 1.6rem;
  font-style: italic;
}

@media screen and (max-width: 1280px) {
  .js3-container-slider h3 {
    font-size: 1.6rem;
    line-height: 1.6rem;
  }
}

@media screen and (max-width: 600px) {
  .js3-container-slider {
    width: 85svw;
    border-radius: 4rem;
    padding: 4rem 2rem;
  }

  .js3-container-slider img {
    width: 100px;
    height: 100px;
  }
}
