.card-container {
  width: 320px;
  margin: 32px auto;
}

.card-text {
  height: 100%;
}

.projects_navbar-container {
  margin-top: 64px;

  /* overflow-x: scroll; */
}

.projects_navbar-container-link {
  font-weight: 700;
  margin-top: 12px;
  color: #777;
  font-size: 12px;
}

.project-link {
  color: rgb(63, 81, 181);
  text-decoration: none;
}

@media only screen and (max-width: 800px) {
  .projects_navbar-container-link {
    font-size: 10px;
  }
}

@media only screen and (max-width: 500px) {
  .projects_navbar-container-link {
    margin: 0px;
  }
}
