.samhalle1-hero-container {
  min-height: 100svh;

  box-shadow: inset 100vw 0 #00000060;
  background-image: url("../../assets/images/samhalle-4.jpg");
  background-position: top;
}

.samhalle1-hero-container-top {
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 10%;
}
.samhalle1-hero-container-top p {
  color: #fff;
  text-align: start;

  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 1.6rem;
  letter-spacing: 0.6rem;
}
.samhalle1-hero-container-top h1 {
  color: #fff;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  text-align: start;

  font-size: 6rem;
  font-weight: 300;
  letter-spacing: 0.8rem;
}
.samhalle1-hero-container-bottom {
  background: #304251d0;
  height: 25vh;

  display: flex;
  justify-items: center;
  align-items: center;
  padding-left: 10%;
}

.samhalle1-hero-container-bottom h3 {
  color: #fff;
  font-weight: 300;
  font-size: 1.6rem;
  width: 40%;
  text-align: start;
  letter-spacing: 0.2rem;
}

@media screen and (max-width: 600px) {
  .samhalle1-hero-container {
    min-height: 100svh;
    background-position: top;
  }

  .samhalle1-hero-container-top {
    padding: 0 1rem;
  }
  .samhalle1-hero-container-top p {
    text-align: start;

    font-size: 1rem;
    letter-spacing: 0.2rem;
  }
  .samhalle1-hero-container-top h1 {
    text-align: start;

    font-size: 2.2rem;
    letter-spacing: 0.6rem;
  }
  .samhalle1-hero-container-bottom {
    padding: 0 1rem;
  }

  .samhalle1-hero-container-bottom h3 {
    color: #fff;
    font-weight: 300;
    font-size: 1rem;
    width: 100%;
    text-align: start;
    letter-spacing: 0.2rem;
  }
}
