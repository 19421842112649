.energi1nav {
  position: absolute;
  top: 0;
  width: 100%;
  height: 12rem;
  padding: 0 4rem;

  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff20;

  z-index: 1;
}

.energi1nav-img {
  flex: 1;
  display: flex;
}
.energi1nav ul {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex: 2;
}
.energi1nav-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* justify-content: flex-start;
  align-items: flex-start; */
  cursor: pointer;
  flex: 1;
}
.energi1nav-link li {
  color: #0c0f2785;
  list-style-type: none;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.4rem;

  transition: 0.3s;
  &:hover {
    color: #0c0f27;
  }
}

.energi1nav-link-icon-active {
  color: #ffab11;

  transition: 1s;
}

.energi1nav-link-icon {
  color: #ffac1100;
  transition: 0.5s;
}

/* .hover-underline {

  top: -18px;
  left: 50%;
  transform: translateX(-50%);
  width: 4rem;
  height: 4rem;
  opacity: 0;
  background: #f872043d;
  transition: 0.2s ease-in;
  border-radius: 10rem;
} */

@media screen and (max-width: 560px) {
  .energi1nav {
    height: 6rem;
    padding: 0 1rem;
  }

  .energi1nav-img img {
    width: 80%;
  }
  .energi1nav ul {
    flex: 3;
  }
  .energi1nav-link {
    display: flex;

    justify-content: center;
    align-items: center;
  }
  .energi1nav-link li {
    font-size: 0.6rem;
    letter-spacing: 0rem;

    padding-top: 4px;
  }

  .hover-underline {
    height: 1px;
  }

  .energi1nav-link-icon-active {
    display: none;
  }

  .energi1nav-link-icon {
    display: none;
  }
}
