.bygg2section3 {
  background-image: url("../../assets/images/house-area-2.jpg");

  width: 100%;
  min-height: 60svh;
  max-height: 60svh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 10rem 0;
  box-shadow: inset 0 100rem 100rem #00000040;
}

.bygg2section3 h1 {
  color: #fff;
  text-align: center;
  font-size: 2.4rem;
  font-weight: 500;
}
.bygg2section3 p {
  color: #fff;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 400;
  width: 80%;
}

.bygg2section3 button {
  background: #f87104;
  border-radius: 100rem;

  &:hover {
    color: #fff;
  }
}

@media screen and (max-width: 560px) {
  .bygg2section3 {
    background-image: url("../../assets/images/house-area-2.jpg");

    width: 100%;
    min-height: 60svh;
    max-height: 100svh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 0;
    padding: 2rem 1rem;
  }

  .bygg2section3 h1 {
    text-align: center;
    font-size: 1.6rem;
    font-weight: 500;
  }
  .bygg2section3 p {
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    width: 100%;
    padding: 2rem 0;
  }

  .bygg2section3 button {
    padding: 1rem 4rem;
    font-size: 1rem;
  }
}
