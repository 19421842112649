.image-section {
  position: relative;
  z-index: 2; /* Under texten men ovanpå overlay */
  display: flex;
  justify-content: center;
  align-items: center; /* Placera bilderna längst ner */
  height: 50%; /* Halva skärmhöjden för bilder */
  width: 50%;
  opacity: 0.9;
}

.image-container {
  position: relative;
  width: 100%;
  height: 50vh; /* Full höjd för skärmen */
  overflow: hidden; /* Dölj allt utanför behållaren */
}

.overlay-image {
  position: absolute; /* Tillåter exakt placering */
  object-fit: cover; /* Behåller bildens proportioner */
  /* border: 1px solid white; */
  box-shadow: 0 0 0.5rem #ffffff6c;
  border-radius: 0.4rem; /* Rundade hörn */
  aspect-ratio: 4 / 3; /* Gör bilder bredare än höga */
  transition: opacity 0.5s ease-in-out; /* Fade-effekt vid byte */
}

/* Placering av stora bilden i mitten */
.large {
  width: 40%; /* Gör den stora bilden mer framträdande */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3; /* Den stora bilden ligger överst */
}

/* Placering av mindre bilder runtomkring */
.small {
  width: 15%; /* Små bilder */
  z-index: 2; /* Ligger under den stora bilden */
  opacity: 1;
}

/* Specifik positionering för varje mindre bild */
.top-left {
  top: 0;
  left: 20%;
  width: 20%;
}

.top-right {
  top: 5%;
  right: 20%;
  width: 20%;
}

.bottom-left {
  bottom: 5%;
  left: 10%;
  width: 25%;
  z-index: 2;
}

.bottom-right {
  bottom: 10%;
  right: 10%;
}

.middle-left {
  top: 50%;
  left: 0%;
  transform: translateY(-50%);
  z-index: 1;
}

@media only screen and (max-width: 780px) {
  .image-section {
    position: relative;
    z-index: 2; /* Under texten men ovanpå overlay */
    display: flex;
    justify-content: center;
    align-items: center; /* Placera bilderna längst ner */
    height: 50%; /* Halva skärmhöjden för bilder */
    width: 75%;
    opacity: 0.9;
  }

  .image-container {
    position: relative;
    width: 100%;
    height: 30vh; /* Full höjd för skärmen */
    overflow: hidden; /* Dölj allt utanför behållaren */
  }

  .overlay-image {
    position: absolute; /* Tillåter exakt placering */
    object-fit: cover; /* Behåller bildens proportioner */
    /* border: 1px solid white; */
    box-shadow: 0 0 0.5rem #ffffff6c;
    border-radius: 0.4rem; /* Rundade hörn */
    aspect-ratio: 4 / 3; /* Gör bilder bredare än höga */
    transition: opacity 0.5s ease-in-out; /* Fade-effekt vid byte */
  }

  /* Placering av stora bilden i mitten */
  .large {
    width: 50%; /* Gör den stora bilden mer framträdande */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3; /* Den stora bilden ligger överst */
  }

  /* Placering av mindre bilder runtomkring */
  .small {
    width: 15%; /* Små bilder */
    z-index: 2; /* Ligger under den stora bilden */
    opacity: 1;
  }

  /* Specifik positionering för varje mindre bild */
  .top-left {
    top: 0;
    left: 20%;
    width: 20%;
  }

  .top-right {
    top: 5%;
    right: 20%;
    width: 20%;
  }

  .bottom-left {
    bottom: 5%;
    left: 10%;
    width: 25%;
    z-index: 2;
  }

  .bottom-right {
    bottom: 10%;
    right: 10%;
  }

  .middle-left {
    top: 50%;
    left: 0%;
    transform: translateY(-50%);
    z-index: 1;
  }
}
