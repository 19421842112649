.js1-color_palette_container {
  margin: 2rem;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.js1-color {
  width: 30px;
  height: 30px;
  border: 2px solid #333;
  display: inline-block;
  margin: 1rem;
  border-radius: 50%;
  cursor: pointer;
}
