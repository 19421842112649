.lilitha1_container {
  min-height: 100svh;
}

.grid-container {
  display: grid;
  grid-template-columns: auto 50%; /* 2 rows */
  /* background-color: #2196f3; */
  padding: 10px;

  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.grid-item {
  /* background-color: rgba(255, 255, 255, 0.8); */
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  padding: 20px;
  font-size: 30px;
  /* text-align: center; */

  display: flex;
  justify-content: center;
  align-items: center;
}
