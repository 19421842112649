.htk5-footer_container {
  /* border: 1px solid black; */

  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  /* background: var(--color-htk-3); */

  /* position: relative;
  bottom: 0px; */
  padding-bottom: 2rem;
}

.htk5-footer_container p {
  font-family: "Quicksand", sans-serif;
}

.htk5-footer_container-contact_container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 75%;
}
.htk5-footer_container-contact_container-contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 500px; */
}
.htk5-footer_container p {
  padding: 0;
  margin: 0;
  color: #333;
  font-size: 1.6rem;
}
.htk5-footer_container-contact_container h4 {
  padding: 0;
  margin: 0;
  color: #333;
}
.htk5-footer_container-contact_container-linebreak {
  width: 2px;
  background: #333;
  height: 20px;
}
.htk5-footer_container-contact_container-open {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.htk5-footer_container-contact_container-open h4 {
  padding: 0;
  margin: 0;
  font-size: 1.6rem;
  margin-right: 2rem;
  color: #333;
}

.htk5-footer_container-contact_container-open p {
  padding: 0;
  margin: 0;
  color: #fff;
}

.htk5-footer_container-contact_container-open p {
  font-size: 1.2rem;
  font-weight: 700;
  color: #333;
  font-family: "Quicksand", sans-serif;
}
.htk5-footer_container-social {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 10%;
}

.htk5-footer_container-social a {
  cursor: pointer;
  font-size: 2.5rem;
  color: #333;
}

@media screen and (max-width: 720px) {
  .htk5-footer_container-contact_container {
    width: 80%;
    flex-direction: column;
  }

  .htk5-footer_container p {
    font-size: 1rem;
  }
  .htk5-footer_container-contact_container p {
    font-size: 1rem;
    font-weight: 500;
    padding: 1rem;
  }
  .htk5-footer_container-social {
    justify-content: center;
    padding: 1rem 0;
  }
  .htk5-footer_container-contact_container-linebreak {
    display: none;
  }
}
