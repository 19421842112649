.htk3-navbar_container {
  /* border: 1px solid black; */
  /* flex: 1; */
  min-height: 10vh;
  max-height: 10vh;

  background: #0000004b;
  border-radius: 100px;

  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  transition: all 0.5s ease-in-out;

  position: absolute;
  top: 24vh;

  z-index: 10;
  overflow: hidden;
}
.htk3-navbar_container-main {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  margin-top: 1rem;
}

.htk3-navbar_container-sub {
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  min-height: 70vh;
  max-height: 70vh;
  margin-top: -100%;
  visibility: hidden;
}

#htk3-nav-logo {
  width: 80px;
  /* height: 2rem; */
  margin: 0;
  padding: 0;
  object-fit: cover;

  /* left: 6vw; */
}

.htk3-navbar_container a {
  color: #fff;
  text-decoration: none;
  font-size: 1.4rem;
  /* padding: 1rem 0rem; */
  cursor: pointer;
  transition: all 0.2s ease-in;
  opacity: 0.8;
  width: 260px;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 1;
  }
}

.htk3-navbar_container span {
  color: #fff;
  text-decoration: none;
  font-size: 1.4rem;
  padding: 1rem 0rem;
  cursor: pointer;
  transition: all 0.2s ease-in;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}

#htk3-navbar_container-icon {
  font-size: 2.8rem;
  width: 260px;

  color: #fff;
  transition: all 0.2s ease-in;

  display: flex;
  justify-content: center;

  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
}

#htk3-navbar_container-blue {
  background: var(--color-htk-2);
  border-radius: 2rem;
  padding: 0.8rem 2rem;
  opacity: 1;

  &:hover {
    background: #fff;
    color: var(--color-htk-2);
  }
}

@media screen and (max-width: 800px) {
  .htk3-navbar_container {
    width: 90%;
    border-radius: 2rem;
  }
}

@media screen and (max-width: 680px) {
  .htk3-navbar_container {
    top: 10vh;
  }

  .htk3-navbar_container a {
    font-size: 1.2rem;
    padding: 1rem 0rem;
  }

  #htk3-navbar_container-icon {
    font-size: 2.4rem;
    padding-top: 0.2rem;
  }

  #htk3-navbar_container-blue {
    border-radius: 2rem;
    padding: 0.8rem 2rem;
  }
}

@media screen and (max-width: 520px) {
  .htk3-navbar_container {
    border-radius: 2rem;
    min-height: 60px;
    max-height: 60px;
  }
  #htk3-nav-logo {
    width: 60px;
  }
  .htk3-navbar_container a {
    font-size: 0.6rem;
    padding: 0;
    margin: 0;
  }

  .htk3-navbar_container-main {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    padding: 0 1rem;
    /* margin-top: 1.2rem; */
  }

  #htk3-navbar_container-icon {
    font-size: 1.4rem;
    padding: 0;
  }

  #htk3-navbar_container-blue {
    padding: 0.4rem;
    /* padding-top: 0.6rem; */

    border-radius: 2rem;
  }
}
