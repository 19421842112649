#root {
  margin: 0;
  padding: 0;
  text-align: center;

  width: 100%;
  min-height: 100svh;

  --color-htk: #3266cc;
  --color-htk-2: #344da9;
  --color-htk-3: #41576c;

  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  /* color-scheme: light dark; */
  color: rgba(255, 255, 255, 0.87);
  background-color: #fff;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.htk5-app-container {
  background: #f7f7f7;
}

a {
  font-weight: 500;
  font-size: 1.2em;
  color: #333;
  text-decoration: inherit;

  transition: color 0.3s ease-in-out;
}
a:hover {
  color: #1974d2;
}

body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
}

/* h1 {
  font-size: 3.2em;
  line-height: 1.1;
  color: #333;
}

h3 {
  font-size: 1.6em;
  color: #333;
}

p {
  font-size: 1.6em;
  line-height: 1.1;
  color: #333;
} */

button {
  /* border-radius: 0.2em; */
  border: 1px solid transparent;
  padding: 1.6rem 4rem;
  font-size: 1.4rem;
  font-weight: 500;
  font-family: inherit;
  background-color: #1560bd;
  color: #fff;
  cursor: pointer;
  opacity: 0.8;
  transition: all 0.5s;
}
button:hover {
  /* border-color: #fff; */
  opacity: 1;
  background-color: #fff;
  color: #1560bd;
}

.sub_button {
  border: 1px solid #1560bd;
  color: #fff;
  background-color: transparent;
}
