.htk3-desc2_container {
  min-height: 100svh;
  max-height: 100svh;
  box-sizing: border-box;

  /* background: linear-gradient(75deg, #344da9 50%, transparent 50%); */
  /* background: repeating-linear-gradient(
    75deg,
    #344da9,
    #344da9 1042px,
    transparent 0,
    transparent 1580px
  ); */

  background: linear-gradient(75deg, transparent 1604px, #3265cc21 1481.5px);
  /* background: #3265cc21; */
  padding: 6rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.htk3-desc2_container-content {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  gap: 4rem;
  padding: 2rem;
}

.htk3-desc2_container-empty {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.htk3-desc2_container-empty img {
  flex: 1;
  max-width: 100%;
}
.htk3-desc2_container-desc {
  flex: 1;
  color: #333;
  /* margin: 0 6rem; */

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;

  min-height: 60svh;
  max-height: 60svh;
  /* padding: 0 4rem; */
}

.htk3-desc2_container-desc p {
  font-size: 2.2rem;
  line-height: 3rem;
  /* font-weight: 700; */
  color: #fff;
  text-align: start;
  /* background: var(--color-htk-2); */
  color: #333;
}

.htk3-desc2_container-desc button {
  text-transform: uppercase;
  font-size: 1.8rem;
  background: var(--color-htk-2);
  color: #fff;
  border: 2px solid var(--color-htk-2);
  &:hover {
    background: #fff;
    color: var(--color-htk-2);
  }
}

@media screen and (max-width: 560px) {
  .htk3-desc2_container {
    flex-direction: column;
    min-height: 100svh;
    max-height: 100%;
    padding: 0;
    background: var(--color-htk-2);
  }
  .htk3-desc2_container-content {
    flex-direction: column;
    padding: 0;
  }
  .htk3-desc2_container-empty {
    padding: 0;
  }
  .htk3-desc2_container-empty img {
    aspect-ratio: 1/1;
    object-fit: cover;
    width: 100%;
    border-radius: 0.2rem;
  }
  .htk3-desc2_container-desc {
    border-radius: 2rem;
    padding: 0 2rem;
    align-items: center;
  }

  .htk3-desc2_container-desc p {
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #fff;
    padding: 0;
    text-align: center;
  }

  .htk3-desc2_container-desc button {
    padding: 1rem 2rem;
    border-radius: 2rem;
    min-width: 240px;

    font-size: 1rem;
    border: 2px solid #fff;
    opacity: 1;
    margin-top: 2rem;
  }
}
