.napra4_navbar {
  height: 50px;
  background: #ffa500;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
}

.napra4_navbar-logo {
  flex: 1;
  color: #fff;
}
.napra4_navbar-links {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.napra4_navbar-links-link:hover {
  cursor: pointer;
}

.napra4_navbar-links a {
  color: #fff;
  text-transform: uppercase;

  transition: 0.3s;

  &:hover + .napra4_navbar-links-link-underline {
    width: 100%;
  }
}

.napra4_navbar-links-link-underline {
  border-bottom: 3px solid white;
  width: 0;

  transition: 0.3s;
}

@media screen and (max-width: 600px) {
  .napra4_navbar-links a {
    font-size: 0.6rem;
  }
}
