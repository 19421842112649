.htk4-main {
  min-height: 100svh;
  max-width: 100%;
  overflow: hidden;
  /* background: var(--color-htk-4);
  background: #87ceeb; */
  border-radius: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.htk4-main-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;

  border-radius: 2rem;
  margin: 2rem;
  overflow: hidden;
  /* padding: 6rem 0; */

  /* filter: blur(100px); */
  /* transform: translateX(-100%); */
  /* animation: slide-in-slide-out forwards;
  animation-timeline: view(block auto); */
}

@keyframes slide-in-slide-out {
  0% {
    filter: blur(100px);
    transform: translateX(-100%);
  }
  25% {
    filter: blur(0);
    transform: translateX(0);
  }
  50% {
    filter: blur(0);
    transform: translateX(0);
  }
  75% {
    filter: blur(0);
    transform: translateX(0);
  }
  100% {
    filter: blur(100px);
    transform: translateX(100%);
  }
}

.htk4-main-content img {
  width: 50%;
  height: 75svh;
  object-fit: cover;
  image-rendering: optimizeSpeed;
}

.htk4-main-content-desc {
  width: 50%;
  max-width: 100%;
  padding: 2rem 4rem;

  color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.htk4-main-content-desc h1 {
  font-size: 3rem;
  margin: 0;
}
.htk4-main-content-desc p {
  font-size: 1.6rem;
}

.htk4-main-content-desc button {
  all: unset;
  border: 2px solid #fff;
  padding: 1rem 4rem;
  font-size: 1.6rem;
  cursor: pointer;

  transition: 0.3s;

  &:hover {
    background: #fff;
    color: var(--color-htk-4);
  }
}

#htk4-main-content-section_1 {
  background: var(--color-htk-3);
}

#htk4-main-content-section_2 {
  background: var(--color-htk-2);
}

#htk4-main-content-section_3 {
  background: var(--color-htk-3);
}

@media screen and (max-width: 800px) {
  .htk4-main-content {
    flex-direction: column;
    animation: none;
    animation-timeline: unset;

    animation-timeline: view(0);
  }

  .htk4-main-content-desc {
    width: 100%;
    padding: 2rem;
  }

  .htk4-main-content img {
    width: 100%;
    height: 60svh;
    object-fit: cover;
    padding: 2rem;
    border-radius: 4rem;
  }

  #htk4-main-content-section_1 {
    padding-top: 0;
  }

  #htk4-main-content-section_2 {
    padding: 0;
    flex-direction: column-reverse;
  }

  #htk4-main-content-section_3 {
    padding-bottom: 0;
  }
}
