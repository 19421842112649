.navbarNapra3-container {
  position: absolute;
  width: 100%;

  display: flex;
  justify-content: space-evenly;

  background: #00000044;
  backdrop-filter: blur(5px);
}

.napra3-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.napra3-logo-container img {
  width: 160px;
  padding-top: 6px;
}

.napra3-links-container {
  width: 75%;
  display: flex;
  justify-content: space-evenly;
}

.napra3-links-container-link {
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  color: #fff;
  text-decoration: none;
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
  font-weight: 400;

  transition: 0.3s;

  &:hover > .napra3-links-container-link-underline {
    height: 4px;
  }

  &:hover > .napra3-links-container-link-overline {
    height: 4px;
  }
}

.napra3-links-container-link-underline {
  height: 0;
  background: var(--color-napra);
  transition: 0.5s;
}

.napra3-links-container-link-overline {
  height: 0;
  background: transparent;
  transition: 0.5s;
}

.napra3-links-container a:hover {
  color: var(--color-napra);
}

@media only screen and (max-width: 800px) {
  .navbarNapra3-container {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    max-height: 50vh;
  }

  .napra3-logo-container {
  }

  .napra3-logo-container img {
    width: 120px;
  }

  .napra3-links-container {
    width: 100%;
  }

  .napra3-links-container a {
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
    color: var(--color-napra);
  }
}
