.htk3-visions_container {
  min-height: 100svh;
  background: #3265cc21;

  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 4rem;
}

.htk3-visions_container-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding-top: 4rem;
}

.htk3-visions_container h1 {
  margin: 0;
}

.htk3-visions_container-vision {
  border-radius: 2rem;
  background: #fff;

  margin: 2rem;
  overflow: hidden;
  flex: 1;
  height: 65svh;
}

.htk3-visions_container-vision-image {
  /* border: 1px solid black; */
  position: relative;
}

.htk3-visions_container-vision-image h1 {
  /* border: 1px solid black; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
}

.htk3-visions_container-vision-image img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  /* border-radius: 2rem; */
}

.htk3-visions_container-vision-text {
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
}

.htk3-visions_container-vision-text h1 {
  color: #344da9;
  font-size: 2.2rem;
  padding: 2rem;
}

.htk3-visions_container-vision-text p {
  color: #333;
  font-size: 1.4rem;
  padding: 0 2rem;
  line-height: 1.4rem;
}

@media screen and (max-width: 920px) {
  .htk3-visions_container {
    min-height: 100svh;
    flex-direction: column;
    padding: 0;
  }

  .htk3-visions_container-content {
    display: flex;
    flex-direction: column;

    padding-top: 0;
  }

  .htk3-visions_container-vision {
    margin: 0;
    margin: 1rem;

    transition: 1s;
  }

  .htk3-visions_container-vision-text h1 {
    color: #344da9;
    font-size: 1.8rem;
    padding-top: 1rem;
    padding-bottom: 0;
  }

  .htk3-visions_container-vision-text p {
    font-size: 1.4rem;
    padding: 1.4rem;
  }
}
