.landing {
  position: relative;
  background: var(--color-theme);
  height: 100svh;
  overflow: hidden;

  background: #fff;
  background: #aaa;
}

.landing__content {
  height: 100svh;
  width: 100%;

  display: flex;
  flex-direction: column;
}

.landing__content h1 {
  z-index: 1;
  color: #fff;
  font-size: 5.2rem;

  font-family: "Lora", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.landing__content p {
  color: #fff !important;
  font-size: 1.8rem !important;
  z-index: 1;
  font-weight: 400 !important;
  padding: 4rem;
  width: 75%;

  /* font-family: "Lora", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal; */
}

.landing__content-button {
  width: 33%;
  border-radius: 10rem;
  padding: 0.6rem;

  display: flex;
  justify-content: space-between;
  background: #eee;

  box-shadow: 0 0 0.1rem #ffffff;
}

.landing__content-button input {
  all: unset;
  font-size: 1.6rem;
  color: #555;
}

.landing__content-button input::placeholder {
  color: #555;
  font-size: 1.4rem;
}

.landing__content-button button {
  border-radius: 10rem;
  background: var(--color-theme);
  z-index: 1;

  &:hover {
    color: var(--color-theme);
  }
}

.landing-image {
  position: absolute;
  top: -40%;

  /* left: 0; */
  right: -15%;
  width: 75%;
  height: 100%;

  transform: rotate(-25deg);

  background-image: url("../assets/bygg-1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;

  /* border: 1px solid black; */
}

.landing__content-buttons {
  position: absolute;
  bottom: 24rem;
}

.project-button {
  width: 200px;
  height: 200px;
}

.project-button span {
  font-size: 1.2rem;
}

.landing__content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing__about {
  padding: 4rem;
  margin-bottom: 36px;
  /* background: var(--color-theme); */
  /* background-color: #000000a1; */
  /* opacity: 0.8; */
}

.landing__about p {
  font-size: 24px;
}

.landing p {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 1rem;
  /* color: var(--color-theme); */
  color: #fff;
  letter-spacing: -0.8px;
  /* text-shadow: 0px 0px 0.2px #ccc; */
  text-align: center;
}

@media screen and (max-width: 1440px) {
  .landing p {
    font-size: 20px;
    margin-bottom: 0.8rem;
  }
}

@media screen and (max-width: 768px) {
  .landing {
    padding: 0;
  }

  .landing p {
    font-size: 16px;
    margin-bottom: 0.8rem;
  }
}

.border {
  width: 100%;
  height: 4px;
  background: var(--color-theme);
}

.landing__content-buttons {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.project-button {
  position: relative;
  top: 10vh;

  background: var(--color-theme);
  opacity: 0.9;
  min-width: 100px;
  min-height: 100px;
  /* padding: 2rem 1rem; */
  border-radius: 100px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  font-size: 9px;
  font-weight: 900;
  text-decoration: none;
  color: #fff;

  letter-spacing: 1px;
}

.project-button:hover {
  box-shadow: 0px 0px 6px #000;
}

.project-button a {
  position: absolute;
  top: 4rem;
  margin: 1rem;

  animation: move 1.1s infinite ease-in-out;
}

@keyframes move {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 6px;
  }
  100% {
    margin-top: 0;
  }
}

@media screen and (max-width: 768px) {
  .project-button {
    position: relative;
    top: 8vh;

    background: var(--color-theme);
    opacity: 0.9;
    min-width: 75px;
    min-height: 75px;

    font-size: 7px;
    font-weight: 900;

    letter-spacing: 1px;
  }

  .project-button_arrow {
    position: absolute;
    top: 2.8rem;
    margin: 1rem;

    animation: move 1.1s infinite ease-in-out;
  }

  @keyframes move {
    0% {
      margin-top: 0;
    }
    50% {
      margin-top: 2px;
    }
    100% {
      margin-top: 0;
    }
  }
}
