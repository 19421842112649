body {
  background-color: black; /* Svart bakgrund */
  opacity: 1; /* Full synlighet */
  transition: opacity 0.5s ease-in-out, background-color 0.5s ease-in-out; /* Smidig övergång */
}

body.fade-out {
  opacity: 0; /* Gör sidan osynlig */
  background-color: black; /* Bibehåll svart när opaciteten minskar */
}

.app-container {
  background: white;
  display: flex;
  flex-direction: column;
  background: #111;
}

header {
  position: relative;
  width: 100%;
  /* height: 40px; */
  /* background: white; */
  padding: 1rem 6rem;
  /* padding-top: 42px; */
  z-index: 10;
  background: #4683b4cb;
  background: #111;
  padding: 2rem 4rem;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: transparent;
}

.links-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 4rem;
}

.link {
  position: relative;
  color: #ddd;
  font-size: 1.2rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  font-weight: 400;

  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
}

.link::before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 0;
  height: 3px;
  background: #fff;
  transition: width 0.3s ease;
}

.link:hover::before {
  width: 100%;
}

.navbar_button-container {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 50px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  display: none;
}

.navbar_button-container p {
  font-size: 1.5rem;
  transition: scale 0.2s ease;
}

.navbar_button-container:hover p {
  scale: 1.1;
}

.navbar_button-container span {
  width: 30px;
  height: 2px;
  background: #333;
}

.navbar_button-container2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  width: 50px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  display: none;

  /* animation: move 1.1s infinite ease-in-out; */
}

.navbar_button-container2 p {
  font-size: 1.5rem;
  transition: scale 0.2s ease;
}

.navbar_button-container2:hover p {
  scale: 1.1;
}

.navbar_button-container2 span {
  width: 30px;
  height: 2px;
  background: #333;
}

/* .menu-buttom {
	display: none;
} */

#menu-button-text {
  display: none;
}

@media screen and (max-width: 800px) {
  nav {
    background: #4683b4f5;
    position: absolute;
    top: 50px;
    right: 0;
    width: 100%;
    height: 0px;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    z-index: 50;
    overflow: hidden;
    transition: height 0.5s ease-in-out;
  }

  header {
    padding: 0;
    height: 50px;
    z-index: 50;
    /* box-shadow: 2px 2px 6px #333; */
  }

  .links-container {
    flex-direction: column;
    align-items: center;
    /* gap: 1rem; */
  }

  .link {
    font-size: 1rem;
    padding: 0.2em;
    margin: 0;
  }

  .menu-button {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  .menu-button span {
    width: 15px;
    height: 2px;
    background: #fff;
    transform-origin: left;
    transition: 0.5s ease;
  }

  .menu-button input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
    appearance: none;
  }

  .menu-button input:checked ~ .second-line {
    opacity: 0;
  }

  .menu-button input:checked ~ .first-line {
    transform: rotate(45deg);
  }

  .menu-button input:checked ~ .third-line {
    transform: rotate(-45deg);
  }

  .menu-button-2 {
    display: flex;
    flex-direction: column;
    gap: 3px;

    /* background: tan; */
    width: 100%;
  }

  .menu-button-2 span {
    width: 15px;
    height: 2px;
    background: transparent;
    transform-origin: left;
    transition: 0.5s ease;
  }

  .menu-button-2 input {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
    appearance: none;
  }

  .menu-button-2 input:checked ~ .second-line {
    opacity: 0;
  }

  .menu-button-2 input:checked ~ .first-line {
    transform: rotate(45deg);
  }

  .menu-button-2 input:checked ~ .third-line {
    transform: rotate(-45deg);
  }

  header:has(input:checked) > nav {
    height: calc(108.5vh - 80px);
  }

  .search-button {
    display: none;
  }

  #nav-projects {
    position: absolute;
    top: 0;
    left: 0;
    /* right: 50%; */
    width: 50%;
    /* min-width: 220px; */

    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: none;
  }

  #menu-button-text {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    padding-top: 6px;

    /* animation: move-hori 1.1s infinite ease-in-out; */

    /* padding-left: 46px;
    padding-top: 3px; */
    /* transform: translateX(50%); */
  }

  .menu-button-icon {
    margin: 2px;
    padding-bottom: 1.5px;
  }
}

@media screen and (max-width: 640px) {
  #nav-projects {
    /* min-width: 320px; */
  }
}
