.desc_container {
  min-height: 75svh;
  max-height: 75svh;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  background: #f7f7f7;
  padding: 0 12rem;
}

.desc_container h1,
span {
  font-size: 4rem;
  font-weight: 500;
  color: #333;

  font-family: "Quicksand", sans-serif;
  /* text-transform: capitalize; */
}

.desc_container p {
  font-size: 1.6rem;
  font-weight: 500;
  color: #333;
  padding-left: 6rem;

  font-family: "Lato", serif;
  width: 75%;
}

#color-green {
  color: #10752b;
}

@media screen and (max-width: 560px) {
  .desc_container {
    min-height: 75svh;
    max-height: 75svh;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    background: #f7f7f7;
    padding: 0 2rem;
  }

  .desc_container h1,
  span {
    font-size: 1.6rem;
    font-weight: 500;
    color: #333;

    font-family: "Quicksand", sans-serif;
    /* text-transform: capitalize; */
  }

  .desc_container p {
    font-size: 1.2rem;
    font-weight: 500;
    color: #333;
    padding-left: 0;

    font-family: "Lato", serif;
    width: 100%;
  }
}
