.htk1-home-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    background: #ffffffe7;

    position: absolute;
    top: 18rem;
    right: 6rem;
    padding: 2rem;

    box-shadow: 2px 2px 6px #555;
    width: 50vw;
    min-height: 50vh;

    z-index: 2;

    transition: 0.5s;
}

.htk1-home-container h3 {
    font-family: 'League Spartan', 'sans-serif', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.htk1-home-container p {
    font-size: 1.6rem;
    padding-bottom: 2rem;
}

.htk1-home-container a {
    font-size: 1.6rem;
    text-decoration: none;
    color: #fff;

    background: #4683b4da;
    padding: 1rem;
    /* border-radius: 0.2rem; */
    box-shadow: 2px 2px 2px #333;
    opacity: 0.9;

    transition: 0.3s;

    cursor: pointer;
}

.htk1-home-container a:hover {
    opacity: 1;
}

.htk1-home-container_icon {
    font-size: 2rem;
    position: absolute;
    top: 2rem;
    right: 2rem;

    cursor: pointer;
    opacity: 0.8;
    transition: 0.3s;
}

.htk1-home-container_icon:hover {
    opacity: 1;
}

.stadium-logo {
    width: 10rem;
    position: absolute;
    right: 2rem;
    bottom: 1rem;
}

@media only screen and (max-width: 1020px) {

    .stadium-logo {
        width: 6rem;
        right: 1.4rem;
        bottom: 0.6rem;
    }

  }

@media only screen and (max-width: 800px) {

    .htk1-home-container {
        bottom: 1rem;
        top: 62vh;
        right: 1rem;
        width: 90vw;
        padding: 1rem;
    }

    .htk1-home-container h3 {
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.2rem;
        padding-top: 0.2rem;
    }
    
    .htk1-home-container p {
        font-size: 1rem;
        padding-bottom: 0.2rem;
        line-height: 1.2rem;
    }
    
    .htk1-home-container a {
        font-size: 1rem;
        padding: 1rem;
    }

    .htk1-home-container_icon {
        top: 1rem;
        right: 1rem;
    }

    .stadium-logo {
        width: 4rem;
        right: 1.4rem;
        bottom: 0.6rem;
    }

  }