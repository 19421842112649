.htk3-landing_container {
}

.htk3-landing_container-content {
}

.htk3-landing_container-desc_container {
  background: #fff;
}

.htk3-landing_container-desc_container-desc {
  padding: 0 2rem;
}

.htk3-landing_container-desc_container-desc h3 {
  text-align: center;
  color: var(--color-htk-2);
  font-size: 1.6rem;
}

.htk3-landing_container-desc_container-desc p {
  color: var(--color-htk-3);
  font-size: 1.3rem;
  line-height: 1.6rem;
  padding: 1rem 0;
  text-align: center;
}

.htk3-landing_container-desc_container-desc-link {
}

.htk3-landing_container-slider_wrapper {
  /* justify-content: center; */
  /* border-radius: 2rem; */
}

.htk3-landing_container-slider_wrapper-slider {
  position: relative; /* for the absolute position for the slider-nav */
  display: flex;
  align-items: center;
  justify-content: center;
}

.htk3-landing_container-slider_wrapper-slider::-webkit-scrollbar {
  display: none;
}

.htk3-landing_container-slider_wrapper-slider img {
  aspect-ratio: 1/1;
  object-fit: cover;
  width: 100%;
  border-radius: 0.2rem;
}

.htk3-landing_container-slider_nav-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  column-gap: 1rem;
  width: 90%;
  padding: 0.2rem 1rem;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.4rem;
  border-radius: 10rem;

  z-index: 2;
  width: 50%;

  background: none;
  border: 2px solid #fff;
  cursor: pointer;
  transition: 0.5s;
}

.htk3-landing_container-slider_nav-button-link button {
  all: unset;
  font-size: 0.7rem;
  text-transform: uppercase;
  color: #fff;
}

.htk3-landing_container-slider_nav {
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: absolute;
  top: 50%;
  column-gap: 1rem;
  width: 90%;
  padding: 0.2rem 1rem;
  left: 50%;
  transform: translate(-50%, -50%);
}

.htk3-landing_container-slider_nav a {
  width: 100%;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 10rem;
  background: #fff;
  gap: 1rem;
  /* opacity: 0.75; */
  /* border: 1px solid black; */

  transition: opacity ease 250ms;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.htk3-landing_container-slider_nav span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem;
}

@keyframes drop-down {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-slider {
  to {
    opacity: 1;
  }
}

.animation {
  opacity: 0;

  animation: fade-in-slider 1s forwards ease-in-out;
}

/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .htk3-landing_container {
    padding: 4rem;
    background: linear-gradient(75deg, #fff 1360px, #3265cc21 0);
    min-height: 140svh;
    max-height: 140svh;
  }

  .htk3-landing_container-content {
    width: 100%;
  }

  .htk3-landing_container-desc_container {
    background: transparent;

    display: flex;
    flex-direction: column;
    background: #00000010;
    border-radius: 0.2rem;
    box-shadow: 0 0 0.4rem#00000059;
    margin: 8rem;
  }

  .htk3-landing_container-desc_container-desc {
    padding: 2rem 6rem;
  }

  .htk3-landing_container-desc_container-desc h3 {
    color: var(--color-htk-2);
    font-size: 2.2rem;

    transform: translateY(100%);
    animation: drop-down 1s forwards;
    animation-delay: 0.6s;
    opacity: 0;
    margin-top: 4rem;
  }

  .htk3-landing_container-desc_container-desc p {
    color: var(--color-htk-3);
    font-size: 1.6rem;
    line-height: 1.6rem;
    color: #333;
    text-align: start;

    transform: translateY(100%);
    animation: drop-down 1s forwards;
    animation-delay: 0.6s;
    opacity: 0;
  }

  .htk3-landing_container-desc_container-desc-link {
  }

  .htk3-landing_container-slider_wrapper {
    position: relative;
  }

  .htk3-landing_container-slider_wrapper-slider {
  }

  .htk3-landing_container-slider_wrapper-slider img {
    width: 60%;
    aspect-ratio: 16/9;
    margin: 4rem 0;
    margin-bottom: 2rem;
  }

  .htk3-landing_container-slider_nav-button {
    width: 25%;
    top: 55%;
    cursor: pointer;
    transition: 0.5s;
    opacity: 0.8;

    background: var(--color-htk-2);
    border: 2px solid var(--color-htk-2);
    color: #fff;

    &:hover {
      opacity: 1;
      background: #fff;
      border: 2px solid var(--color-htk-2);
      color: var(--color-htk-2);
    }
  }

  .htk3-landing_container-slider_nav-button-link {
    all: unset;
  }

  .htk3-landing_container-slider_nav-button-link a {
    all: unset;
  }

  .htk3-landing_container-slider_nav-button-link button {
    all: unset;
    font-size: 1.4rem;
    text-transform: uppercase;

    &:hover {
      color: var(--color-htk-2);
    }
  }

  .htk3-landing_container-slider_nav-button span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.2rem;

    transition: 0.3s;
    background: #fff;
    color: var(--color-htk-2);

    border-radius: 100%;
    cursor: pointer;

    &:hover {
      background: var(--color-htk-2);
      color: #fff;
    }
  }

  .htk3-landing_container-slider_nav {
    width: 80%;
    top: 55%;
  }

  .htk3-landing_container-slider_nav a {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: #fff;
    /* opacity: 0.75; */
    /* border: 1px solid black; */

    transition: opacity ease 250ms;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }

  .htk3-landing_container-slider_nav span {
    transition: 0.3s;
    color: #333;

    cursor: pointer;
    background: #fff;
    border-radius: 100rem;
    transform: scale(1.2);

    &:hover {
      transform: scale(1.4);
    }
  }
}

/* .landing_container-slider_nav-arrows {
    position: absolute;
    left: 7%;
    right: 7%;
    bottom: -5%;
  
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
  } */
