.lil1_navbar_container {
  /* min-height: 10svh;
  max-height: 10svh; */

  position: absolute;
  left: 0;
  right: 0;

  display: flex;
  justify-content: space-evenly;
  /* align-self: center; */
  max-width: 100%;

  /* border-top: 1px solid #ccc; */
}

.lil1_navbar_container a {
  all: unset;
  margin: 2rem;

  font-size: 1.4rem;
  /* font-weight: 700; */
  text-transform: uppercase;
  color: #777;
  transition: color 0.3s;

  &:hover {
    cursor: pointer;
    color: #b78727;
  }
}

@media screen and (max-width: 600px) {
  .lil1_navbar_container a {
    margin: 0.2rem;

    font-size: 1rem;
  }
}
