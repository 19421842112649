.js11_video_container {
  min-height: 100svh;
  max-height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  z-index: 2;

  position: absolute;
  left: 0;
  top: 0;
  background: #000;

  padding: 12rem;
}

.js11_video_container-video {
  width: 100%;
  height: 50svh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.js11_video_container-close {
  position: absolute;
  top: 5rem;
  right: 5rem;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .js11_video_container {
    padding: 0.2rem;
  }

  .js11_video_container-close {
    top: 1rem;
    right: 1rem;
  }
}
