.htk1-landing-container {
    /* border: 2px solid black; */
    min-height: 100vh;

    background-image: url('../assets/img/start1_notitle.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;

}

.htk1-title-container {
    /* border: 1px solid white; */
    position: absolute;
    bottom: -4rem;
    left: 2.6rem;
    right: 0;
    /* width: 90%; */
    padding: 4rem;
    align-self: center;

    transition: 0.5s;

    z-index: 1;
}

.htk1-title-container h1 {
    color: #fff;
    font-family: 'League Spartan', 'sans-serif', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 6rem;
    font-weight: 700;
    letter-spacing: 1.2rem;
    text-shadow: 2px 2px 6px #333;
    text-align: center;
}

.htk1-title-container h3 {
    color: #fff;
    font-family: 'League Spartan', 'sans-serif', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 4.2rem;
    font-weight: 700;
    letter-spacing: 0.6rem;
    text-shadow: 2px 2px 6px #333;

    text-align: start;
}

.htl1-logo {
    position: absolute;
    bottom: -6rem;
    right: 2rem;

    border-radius: 100%;
    width: 8rem;
    height: 7.2rem;
    opacity: 0.8;
    
    
}

@media only screen and (max-width: 800px) {

    .htk1-landing-container {
        min-height: 50vh;
    
    }

    .htk1-title-container {
        padding: 0rem;
        /* bottom: 0rem; */
        top: -28vh;
        left: 1rem;
    }

    .htk1-title-container h1 {
        font-size: 1.4rem;
        font-weight: 700;
        letter-spacing: 0.6rem;
        margin: 0.6rem;
        text-align: start;
    }
    
    .htk1-title-container h3 {
        font-size: 1.2rem;
        font-weight: 700;
        letter-spacing: 0.4rem;
        text-align: start;
        margin: 0;
        margin-left: 0.6rem;
    }
   
  }
