.brands_container {
    /* padding: 5%; */
    padding: 5%;
    padding-bottom: 5%;
    background: #fff;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #fff, orange);
    background: linear-gradient(to left, #fff, orange); 
}

.brands_reach-out_container-title {
    font-family: 'League Spartan', 'sans-serif', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode';
    
}

.brands_reach-out_container-subtitle {
    font-family: 'League Spartan', 'sans-serif', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode';
}

.brands_reach-out_container {
    /* box-shadow: inset 1px 1px 6px #333;
    border-radius: 25px;
    background: #fff; */
    padding: 5%;
    /* margin: 5%; */
    
}

.brands_reach-out_container-card {
    /* box-shadow: inset 1px 1px 6px #333; */
    /* box-shadow: 4px 4px 6px #999; */
    /* border-radius: 12px; */
    padding: 6px 12px;
    /* margin: 5%; */
    background: #ffffff54;
    margin-bottom: 24px;
}

.brands_container-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 64px;
}

.brands_title {
    text-align: center;
    /* font-style: italic; */
    color: #333;
    background: #ffffff54;
    padding: 12px;
    font-family: 'League Spartan', 'sans-serif', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    margin-top: 82px;
}

.line-break {
    border: 2px solid #333;
    width: 90%;
    margin-left: auto; 
    margin-right: 0;
    margin-bottom: 64px;
}

.brands_logos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.brands_logos-logo-container {
    background: #fff;
    box-shadow: 4px 4px 16px #aaa;
    border-radius: 100px;
    width: 160px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px;
}

.brands_logos img {
    margin: 12px;
    padding: 12px;
    /* border: 1px solid black; */
    /* border-radius: 100%; */
    width: 200px;
    transform: scale(0.5);
    max-width: 100%;
    height: auto;
}

@media only screen and (max-width: 600px) {
    .brands_title {
        font-size: 22px;
        margin-left: 64px;
        margin-right: 64px;
        font-weight: 700;
    }

    .brands_reach-out_container-title {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
    }

    .brands_reach-out_container-subtitle {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
    }

   

    .brands_logos-logo-container {
        background: #fff;
        /* box-shadow: 2px 2px 4px #333; */
        border-radius: 100px;
        width: 120px;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 12px;
    }
  }