.js1-grid_container {
  /* border: 2px solid blue; */
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.js1-grid {
  max-width: 50vw;
  max-height: 50vh;
  display: grid;
  grid-template-rows: repeat(10, 1fr);
  grid-template-columns: repeat(10, 1fr);

  /* min-height: 50vmin;
  max-height: 50vmin;
  min-width: 50vmin;
  max-width: 50vmin; */

  margin-bottom: 2rem;
}

.js1-grid_container-cell_container {
  outline: 1px solid black;
  cursor: pointer;
  background: #fff;
  color: transparent;

  width: 50px;
  height: 50px;

  transition: 0.2s;

  &:hover {
    transform: scale(1.2);
  }
}

@media only screen and (max-width: 800px) {
  .js1-grid_container {
    transform: scale(0.7);
  }

  .js1-grid {
    position: relative;
    left: -10%;
  }
}

@media only screen and (max-width: 600px) {
  .js1-grid_container {
    transform: scale(0.6);
  }
  .js1-grid {
    position: relative;
    left: -25%;
  }
}

@media only screen and (max-width: 400px) {
  .js1-grid_container {
    transform: scale(0.5);
  }
  .js1-grid {
    position: relative;
    left: -50%;
  }
}
