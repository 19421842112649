.htk2_visions_container {

}

.htk2_visions_container-subtitle {
    font-size: 1rem;
    font-weight: 700;
}

.htk2_visions_container-desc {

}