.contact-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #4683b4cb;
  padding: 6rem 0;
  padding-bottom: 0;

  border-left: 1.5rem solid #111;
  border-right: 1.5rem solid #111;
  margin-top: 2rem;
}

.contact-container-form-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1.5rem;
  width: 75%;
}

.contact-container-form-grid-col-span-1-text {
  grid-column: span 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover > .contact-container-grid-col-span-1-text-underline {
    width: 80%;
    opacity: 1;
  }

  &:hover > p {
    /* font-weight: 500; */
    opacity: 1;
  }
}

.contact-container-form-grid-col-span-1-text p {
  color: #fff;
  font-size: 2.2rem;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-weight: 600;

  opacity: 0.8;
  z-index: 1;
  transition: 0.3s;
}

.contact-container-grid-col-span-1-text-underline {
  width: 0;
  height: 3px;
  background: #fff;
  opacity: 0.8;

  transition: 0.3s ease-in-out;
}

.contact-container-form-grid-container-col-3 {
  grid-column: span 3;
  gap: 1.5rem;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
}

.contact-container-form-grid-container-col-3 textarea {
  height: 100%;
  grid-column: span 2;
  border: none;
  padding: 1rem 2rem;

  font-size: 1.6rem;
}

.contact-container-form-grid-container-col-3 button {
  all: unset;
  grid-column: span 1;
  background: #daa520a4;
  border-radius: 0;
  transition: 0.3s ease-in-out;
  font-size: 1.6rem;
  cursor: pointer;

  &:hover {
    background: #daa520;
    color: #fff;
    border-radius: 10rem;
  }
}

.contact-container-form-grid-container-col-3 p {
  font-size: 1.6rem;
  color: #fff;
  min-height: 80px;
  grid-column: span 3;
  padding: 1rem 2rem;

  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-container-form-grid-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.contact-container-form-grid-container label {
  color: #333;
}
.contact-container-form-grid-container input {
  height: 100%;
  width: 100%;

  padding: 1rem 2rem;
  border: none;
  font-size: 1.6rem;
}

@media only screen and (max-width: 1280px) {
}

@media only screen and (max-width: 1020px) {
}

@media only screen and (max-width: 780px) {
  .contact-container {
    padding: 2rem 1rem;
    margin: 1.5rem 0;

    /* border-left: 1.5rem solid #fff;
    border-right: 1.5rem solid #fff; */
  }

  .contact-container-form-grid {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);

    width: 100%;
  }

  .contact-container-form-grid-container-col-3 {
    grid-column: span 1;

    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }

  .contact-container-form-grid-container-col-3 textarea {
    grid-column: span 1;
    padding: 1rem 2rem;

    font-size: 1rem;
  }

  .contact-container-form-grid-container-col-3 button {
    grid-column: span 1;
    font-size: 1rem;
    padding: 1rem;
  }

  .contact-container-form-grid-container-col-3 p {
    font-size: 1rem;
    grid-column: span 1;
    padding: 1rem 2rem;
  }

  .contact-container-form-grid-container input {
    padding: 1rem 2rem;
    font-size: 1rem;
  }
}
