.nav {
  /* border: 1px solid #333; */
  background: var(--color-theme);
  /* opacity: 0.9; */
  position: relative;
  top: 0;
  width: 100%;
  height: 6rem;
  z-index: 5;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 25%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2rem;
}

.logo__text {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  margin-right: 0.5rem;
  font-size: 1.4rem !important;
}

.logo img {
  width: 60px;
}

.link-container {
  width: 75%;

  display: flex;
  flex-direction: column;
}

.links__text {
  font-size: 1.4rem !important;
}

@media screen and (max-width: 1024px) {
  .link-container {
    display: none;
  }

  .logo {
    width: 50%;
  }
}

.links {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.links__text {
  color: #ccc;
  cursor: pointer;
  text-decoration: none;
  font-size: 13px;
  font-weight: 500;

  /* transition-property: color;
	transition-duration: 4s;
	transition-delay: 2s; */
  transition: color 0.5s;

  /* border: 1px solid salmon; */
}

.links__text:hover {
  color: #fff;
}

/* .links a:active {
	color: #fff;
} */

.sub-nav {
  background: var(--color-theme);
  /* background: #333; */
  opacity: 0.9;
  position: absolute;
  top: 3rem;
  left: 0;
  width: 100%;
  height: 2.5rem;
  z-index: 4;

  display: flex;
  justify-content: space-around;
  align-items: center;

  animation-name: onload;
  animation-duration: 0.5s;
  /* animation-iteration-count: 1; */
  animation-timing-function: ease-in 2s;
}

.sub-nav:hover {
  cursor: pointer;
}

@keyframes onload {
  0% {
    height: 0;
  }
  100% {
    height: 2.5rem;
  }
}

.sub-nav2 {
  background: var(--color-theme);
  /* background: #333; */
  opacity: 0.8;
  position: absolute;
  top: 5.5rem;
  left: 0;
  width: 100%;
  height: 2.5rem;
  z-index: 4;

  display: flex;
  justify-content: space-around;
  align-items: center;

  animation-name: onload;
  animation-duration: 0.5s;
  /* animation-iteration-count: 1; */
  animation-timing-function: ease-in 2s;
}

.sub-nav2:hover {
  cursor: pointer;
}

.sub-nav__item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-nav__text {
  color: #ccc;
  cursor: pointer;
  text-decoration: none;
  font-size: 13px;
  font-weight: 500;
  margin-right: 6px;
  transition: color 0.5s;
}

.sub-nav__text:hover {
  color: #fff;
}

/* .sub-nav a:active {
	color: #fff;
} */

.nav__menu {
  margin-right: 2rem;
}

.nav__menu:hover {
  cursor: pointer;
}

@media screen and (min-width: 1025px) {
  .nav__menu {
    display: none;
  }
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
