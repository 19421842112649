.tennisskolan_container {
  min-height: 100svh;
  padding: 0 6em;

  background-image: url("../../assets/images/tennisskolan.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  box-shadow: inset -50vw 0 1000em #0000006c;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  scroll-snap-align: start;

  overflow: hidden;
}

.tennisskolan_container-text {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  animation: from-right 1s forwards;
  animation-delay: 0.4s;
  opacity: 0;
}

.tennisskolan_container-text h1 {
  font-size: 6em;
  letter-spacing: 0.1em;
  color: #fff;
  text-shadow: 0px 0px 2px #000;

  animation: from-left 1s forwards;
  animation-delay: 0.4s;
  opacity: 0;
}

.tennisskolan_container-text p {
  font-size: 1.6rem;
  text-align: start;
  color: #fff;
}

.tennisskolan_container-button {
  margin-top: 4em;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 14em;
}

.tennisskolan_container-button-act_button {
  animation: fade-in 2s forwards;
  animation-delay: 1s;
  opacity: 0;
}

.tennisskolan_container-button-sub_button {
  background: transparent;
  border: 2px solid #1560bd;

  animation: fade-in 2s forwards;
  animation-delay: 1s;
  opacity: 0;

  &:hover {
    background: #1560bd;
    color: #fff;
  }
}

@keyframes from-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes from-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-in {
  50% {
    /* transform: scale(1.2); */
  }
  100% {
    opacity: 1;
    /* transform: scale(1); */
  }
}

.tennisskolan_content {
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.tennisskolan_content-desc {
  padding: 2rem 4rem;
}

.tennisskolan_content-desc p {
  margin-top: 1rem;
}

.tennisskolan_content h1 {
  color: #333;
  text-align: start;
  font-size: 1.8rem;
}

.tennisskolan_content p {
  color: #333;
  text-align: start;
  font-size: 1.4rem;
}

@media screen and (max-width: 1020px) {
  .tennisskolan_container {
    padding-right: 0em;
    align-items: center;

    box-shadow: inset -40em 0 1000em #0000006c;
  }

  .tennisskolan_container-text {
    align-items: flex-start;
  }

  .tennisskolan_container-text h1 {
    font-size: 4em;
  }

  .tennisskolan_container-button {
    flex-direction: column;
    justify-content: space-between;
    gap: 0;
  }

  .tennisskolan_container-button button {
    padding: 1em 2em;
    min-width: 320px;
    margin-top: 2em;
  }
}

@media screen and (max-width: 560px) {
  .tennisskolan_container {
    box-shadow: inset 0px 50svh 10svh #00000025;
  }

  .tennisskolan_container-text h1 {
    font-size: 2.2em;
  }

  .tennisskolan_container-button button {
    padding: 0.8em 1.6em;
    min-width: 260px;
    margin-top: 2em;
    font-size: 0.8em;
  }

  .tennisskolan_container-icon_down {
    bottom: 2.5em;
  }

  @keyframes icon-down {
    0% {
      margin-bottom: 0;
    }
    100% {
      margin-bottom: 1.6em;
    }
  }
}
