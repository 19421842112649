.js13_app {
  min-height: 100svh;
  max-height: 100svh;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #9ab973;
  gap: 4rem;
  padding: 4rem;
}

.js13_app-image_container {
  flex: 1;
  height: 75svh;
  border-radius: 1rem;
  overflow: hidden;
}
.js13_app-image_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.js13_app-tabs_container {
  flex: 1;
  height: 75svh;
  background: #8c9e5e;
  border-radius: 1rem;
  overflow: hidden;
}

.js13_app-tabs_container-tabbar {
  width: 100%;

  display: flex;
}

.js13_app-tabs_container-tabbar button {
  flex: 1;
  padding: 2rem;
  border: none;
  cursor: pointer;

  color: #333;
  font-size: 2rem;

  transition: 0.3s;

  &:hover {
    background: #2e8b57 !important;
  }
}

.js13_app-tabs_container-tab {
  padding: 4rem;
}

.js13_app-tabs_container-tab h1 {
  color: #333;
  font-size: 2.2rem;
  font-weight: 700;
}
.js13_app-tabs_container-tab p {
  color: #333;
  font-size: 1.6rem;
}

@media screen and (max-width: 600px) {
  .js13_app {
    flex-direction: column;

    gap: 2rem;
    padding: 2rem;
  }

  .js13_app-image_container {
    min-height: 25svh;
    max-height: 25svh;
    border-radius: 1rem;
    overflow: hidden;
  }
  .js13_app-tabs_container {
    min-height: 60svh;
    max-height: 60svh;
  }

  .js13_app-tabs_container-tabbar {
    width: 100%;
  }

  .js13_app-tabs_container-tabbar button {
    padding: 1rem 2rem;
    font-size: 1.4rem;
  }

  .js13_app-tabs_container-tab {
    padding: 1rem;
  }

  .js13_app-tabs_container-tab h1 {
    font-size: 1.6rem;
    margin: 1rem 0;
  }
  .js13_app-tabs_container-tab p {
    font-size: 1.2rem;
    line-height: 1rem;
  }
}
