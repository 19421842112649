.footer {
	background: hsl(187.3, 50%, 29%);
	display: flex;
	justify-content: space-evenly;

	/* align-items: center; */
}

.footer__section {
	padding: 2rem 0;
}

.footer__section h5 {
	color: #eee;
	margin-bottom: 1rem;
}

.footer__section p {
	color: #eee;
}

.footer__link {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
	margin-bottom: 4px;
}

.footer__link a {
	color: #eee;
	padding-bottom: 1px;
}

.footer__link a:hover {
	color: var(--color-theme);
}

@media screen and (max-width: 768px) {
	.footer {
		display: flex;
		flex-direction: column;
		padding: 1rem;
	}

	.footer__section {
		padding: 1rem 0;
	}

	.footer__section h5 {
		margin-bottom: 0.5rem;
		font-size: 12px;
	}

	.footer__link {
		margin-bottom: 0px;
		font-size: 12px;
	}

	.footer__link a {
		padding-bottom: 0px;
		font-size: 12px;
	}
}
