.about-me {
  /* background: #2c515e3a; */
  display: flex;
  flex-wrap: wrap;
  /* width: 96.65svw; */

  padding: 1.5rem 1.5rem;
  padding-bottom: 3rem;
  /* padding-bottom: 3rem; */
  gap: 1.5rem;

  /* overflow: hidden; */
}

.about-me-content {
  flex: 1;
  background: #2c515ed0;
  padding: 4rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 25%;

  border-radius: 0;
  /* border: 4px solid #daa520; */
}

.about-me-content h1 {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  color: #fff;
  text-align: start;
  font-size: 1.6rem;
  font-weight: 700;
}
.about-me-content h3 {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  color: #fff;
  text-align: start;
  font-size: 1.6rem;
  font-weight: 500;
}

@media only screen and (max-width: 600px) {
  .about-me {
    width: 100svw;
    padding: 0 1.5rem;
  }
  .about-me-content {
    flex: 1;
    padding: 1.5rem;
    min-width: 100%;
    align-items: center;

    border-radius: 1rem;
  }

  .about-me-content h1 {
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
    padding-bottom: 1rem;
  }
  .about-me-content h3 {
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
  }
}
