@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

:root {
  --font-family: "Manrope", sans-serif;
  --color-theme: #008294;
  --color-napra: #f5a114;
  --color-htk: #344da9;
  --color-htk-2: #3266cc;
  --color-htk-3: #41576c;
  --color-htk-4: #04457a;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body p {
  font-family: "League Spartan", "sans-serif", "Lucida Sans",
    "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode";
}

body h1 {
  font-family: "League Spartan", "sans-serif", "Lucida Sans",
    "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode";
}

body h2 {
  font-family: "League Spartan", "sans-serif", "Lucida Sans",
    "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode";
}

body h3 {
  font-family: "League Spartan", "sans-serif", "Lucida Sans",
    "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode";
}

body h4 {
  font-family: "League Spartan", "sans-serif", "Lucida Sans",
    "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode";
}

body h5 {
  font-family: "League Spartan", "sans-serif", "Lucida Sans",
    "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode";
}

body h6 {
  font-family: "League Spartan", "sans-serif", "Lucida Sans",
    "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode";
}
