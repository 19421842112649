.htk_cards_container {
  display: flex;
  padding: 8rem;
  background: #10752b;
  margin: 4rem;
  border-radius: 4rem;
}

.htk_cards_container-text {
  flex: 1;
}
.htk_cards_container-text h1 {
  font-family: "Lato", sans-serif;
  text-align: start;
  font-size: 5.2rem;
  line-height: 8rem;
  text-transform: uppercase;
}
.htk_cards_container-text p {
  font-family: "Lato", sans-serif;
  text-align: start;
  width: 75%;
  margin-top: 2rem;
  font-size: 1.6rem;
}
.htk_cards_container-form {
  all: unset;
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.htk_cards_container-form input,
.htk_cards_container-form textarea {
  all: unset;
  border-bottom: 1px solid #fff;
  color: #fff;
  text-align: start;
  min-height: 60px;
}

.htk_cards_container-form input::placeholder,
.htk_cards_container-form textarea::placeholder {
  color: #eee;
  font-size: 1.6rem;
  padding: 1rem 0;
  min-height: 60px;
  font-family: "Lato", sans-serif;
}

.htk_cards_container-form button {
  all: unset;
  background: #fff;
  color: #10752b;
  border-radius: 1rem;
  max-width: 200px;
  min-height: 60px;
  font-size: 1.6rem;
  font-family: "Lato", sans-serif;
}

.cards_container-card {
  min-height: 400px;
  max-height: 400px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: #fff;
  border-radius: 1rem;

  transition: 0.3s;
  cursor: default;

  &:hover {
    animation: shake 0.6s forwards ease-in;
    animation-delay: 0.1s;
    background: #fff;
    box-shadow: 0 0 2rem #fff;
  }
}

@keyframes shake {
  0% {
    transform: rotate(1deg);
  }
  25% {
    transform: rotate(2deg);
  }
  50% {
    transform: rotate(-2deg);
  }
  75% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(0);
  }
}

.cards_container-card h3 {
  padding: 0 2rem;
  font-size: 2rem;
  color: var(--color-htk-2);
}
.cards_container-card p {
  padding: 0 2rem;
  font-size: 1.2rem;
  color: var(--color-htk-2);
  /* font-weight: 700; */
}

@keyframes spin {
  to {
    transform: rotate(90deg);
  }
}

.cards_container-card-vision {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.cards_container-card-vision h2 {
  flex: 1;
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--color-htk-2);
  font-style: italic;
}

@media screen and (max-width: 720px) {
  .htk_cards_container {
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
    background: #10752b;
    margin: 1rem;
    border-radius: 2rem;
  }

  .htk_cards_container-text {
    flex: 1;
  }
  .htk_cards_container-text h1 {
    font-family: "Lato", sans-serif;
    text-align: start;
    font-size: 2rem;
    line-height: 2rem;
    text-transform: uppercase;
    font-size: 1.6rem;
  }
  .htk_cards_container-text p {
    font-family: "Lato", sans-serif;
    text-align: start;
    width: 100%;
    margin-top: 2rem;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
  .htk_cards_container-form {
    all: unset;
    flex: 1;

    display: flex;
    flex-direction: column;
    gap: 4rem;
    margin-top: 2rem;
  }

  .htk_cards_container-form input,
  .htk_cards_container-form textarea {
    all: unset;
    border-bottom: 1px solid #fff;
    color: #fff;
    text-align: start;
    min-height: 60px;
    font-size: 1.4rem;
  }

  .htk_cards_container-form input::placeholder,
  .htk_cards_container-form textarea::placeholder {
    color: #eee;
    font-size: 1.4rem;
    padding: 1rem 0;
    min-height: 60px;
    font-family: "Lato", sans-serif;
  }

  .htk_cards_container-form button {
    all: unset;
    background: #fff;
    color: #10752b;
    border-radius: 1rem;
    max-width: 200px;
    min-height: 60px;
    font-size: 1.6rem;
    font-family: "Lato", sans-serif;
  }

  .cards_container-card {
    min-height: 400px;
    max-height: 400px;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background: #fff;
    border-radius: 1rem;

    transition: 0.3s;
    cursor: default;

    &:hover {
      animation: shake 0.6s forwards ease-in;
      animation-delay: 0.1s;
      background: #fff;
      box-shadow: 0 0 2rem #fff;
    }
  }

  @keyframes shake {
    0% {
      transform: rotate(1deg);
    }
    25% {
      transform: rotate(2deg);
    }
    50% {
      transform: rotate(-2deg);
    }
    75% {
      transform: rotate(-1deg);
    }
    100% {
      transform: rotate(0);
    }
  }

  .cards_container-card h3 {
    padding: 0 2rem;
    font-size: 2rem;
    color: var(--color-htk-2);
  }
  .cards_container-card p {
    padding: 0 2rem;
    font-size: 1.2rem;
    color: var(--color-htk-2);
    /* font-weight: 700; */
  }

  @keyframes spin {
    to {
      transform: rotate(90deg);
    }
  }

  .cards_container-card-vision {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .cards_container-card-vision h2 {
    flex: 1;
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--color-htk-2);
    font-style: italic;
  }
}
