.energi1section2 {
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  /* border: 2px solid red; */
  /* padding: 16rem; */
}

.energi1section2-content {
  display: flex;

  /* background: #f87104; */
}

.energi1section2 h1 {
  /* color: #f87104; */
  color: #333;
  font-size: 2.6rem;
  font-weight: 300;
  text-align: start;

  animation: fade-in-text;
  animation-timeline: view();
}

.energi1section2-content-image {
  min-height: 100svh;
  max-height: 100svh;

  display: flex;
}

.energi1section2-content-text {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;

  padding: 4rem;
  margin: 8rem 4rem;
  border-radius: 2rem;

  background: #0c0f27dc;
  border: 1px solid #0c0f27;
  box-shadow: 0 0 0.6rem #0c0f27a4;
}
.energi1section2-content-text h1 {
  color: #fff;
  text-align: start;
  font-size: 4rem;
  padding-bottom: 1rem;
}
.energi1section2-content-text p {
  color: #fff;
  text-align: start;
  font-size: 1.8rem;
  font-weight: 300;
  padding-top: 2rem;
}

.energi1section2-content-text button {
  border-radius: 10rem;
  height: 6rem;
  background: #ffffff42;
  border: 1px solid #fff;
  box-shadow: 0 0 0.6rem #ffffffa4;
  font-size: 1.6rem;
  text-transform: uppercase;
  margin-top: 4rem;

  &:hover {
    color: #0c0f27;
    background: #ffffff9f;
    box-shadow: 0 0 0.8rem #ffab11a4;
  }
}

.energi1section2-content-text-small {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;

  padding: 1rem 2rem;
  padding-left: 4rem;
  margin: 10% 5%;
  border-radius: 2rem;

  background: #ffffff42;
  border: 1px solid #fff;
  box-shadow: 0 0 0.6rem #ffffffa4;

  max-height: 30svh;
  min-width: 30vw;
}

.energi1section2-content-text-small h1 {
  color: #0c0f27;
  font-weight: 400;
  letter-spacing: 0.1rem;
  text-align: start;
}

.energi1section2-content-text-small ul {
  padding-top: 1rem;
  padding-left: 1rem;

  display: flex;
  flex-direction: column;
  width: 100%;
}
.energi1section2-content-text-small li {
  color: #0c0f27;
  font-size: 1.8rem;
  font-weight: 300;
  text-align: start;
  padding-left: 2rem;
  letter-spacing: 0.1rem;
}

#energi1section2-content-1 {
  background-image: url("../../assets/images/vind-6.jpg");
  background-position: center;
  background-size: cover;
}

#energi1section2-content-2 {
  background-image: url("../../assets/images/sol-1.jpg");
  background-position: center;
  background-size: cover;
}

#energi1section2-content-3 {
  background-image: url("../../assets/images/bygg-6.jpg");
  background-position: center;
  background-size: cover;
}

#energi1section2-content-4 {
  background-image: url("../../assets/images/bygg-5.jpg");
  background-position: center;
  background-size: cover;
}

@media screen and (max-width: 560px) {
  .energi1section2-content {
    display: flex;

    /* background: #f87104; */
  }

  .energi1section2-content-image {
    min-height: 100svh;
    max-height: 100%;

    display: flex;
    flex-direction: column;

    padding: 1rem;
    gap: 1rem;
  }

  .energi1section2-content-text {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;

    padding: 1rem;
    margin: 0;
    border-radius: 2rem;

    background: #0c0f27dc;
    border: 1px solid #0c0f27;
    box-shadow: 0 0 0.6rem #0c0f27a4;
  }
  .energi1section2-content-text h1 {
    color: #fff;
    text-align: start;
    font-size: 1.6rem;
    padding-bottom: 1rem;
  }
  .energi1section2-content-text p {
    color: #fff;
    text-align: start;
    font-size: 1rem;
    font-weight: 300;
    padding-top: 2rem;
  }

  .energi1section2-content-text button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;

    width: 100%;
    border-radius: 10rem;
    height: 4rem;
    background: #ffffff42;
    border: 1px solid #fff;
    box-shadow: 0 0 0.6rem #ffffffa4;
    font-size: 1rem;
    text-transform: uppercase;
    margin-top: 1rem;

    &:hover {
      color: #0c0f27;
      background: #ffffff9f;
      box-shadow: 0 0 0.8rem #ffab11a4;
    }
  }

  .energi1section2-content-text-small {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 1rem 0;
    margin: 0;
    border-radius: 1rem;

    background: #ffffff42;
    border: 1px solid #fff;
    box-shadow: 0 0 0.6rem #ffffffa4;

    max-height: 30svh;
    min-width: 30vw;
  }

  .energi1section2-content-text-small h1 {
    color: #0c0f27;
    font-weight: 400;
    font-size: 1.6rem;
    letter-spacing: 0.1rem;
    text-align: center;
  }

  .energi1section2-content-text-small ul {
    padding: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .energi1section2-content-text-small li {
    color: #0c0f27;
    font-size: 1rem;
    font-weight: 300;
    text-align: start;
    padding: 0;
    letter-spacing: 0.1rem;
  }
}
