* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: var(--font-family);
	/* scroll-behavior: smooth;
	scroll-margin-top: 1.5rem; */
}

.app {
	/* global 94%+ browsers support */
	/* background: linear-gradient(0deg, rgba(51, 51, 51, 1) 25%, rgba(255, 255, 255, 1) 100%); */
	/* padding-top: 3rem; */
	min-width: 100%;
}
