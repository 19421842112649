.hero_container {
  position: relative;
  min-height: 100svh;

  padding-top: 12svh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  scroll-snap-align: start;

  filter: grayscale(0.4);
  box-shadow: inset 0 100rem 1000rem #00000060;
  margin: 1rem;

  background-image: url("../../assets/images/thk-hero.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.hero_container-left_background {
  flex: 1;

  position: absolute;
  left: 2rem;
  top: 2rem;
  bottom: 2rem;

  width: 45%;
  max-height: 100svh;
}
.hero_container-right_background {
  background-image: url("../../assets/images/1945.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  flex: 1;

  position: absolute;
  top: 2rem;
  bottom: 2rem;
  right: 2rem;

  width: 50%;
  max-height: 100svh;

  box-shadow: inset 0 100rem 1000rem #00000060;
}

.hero_container-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero_container-text h2 {
  font-size: 4rem;
  letter-spacing: 4px;
  color: #f7f7f7;
  margin-bottom: 1em;

  animation: hero-from-left 2s forwards;
  animation-delay: 0.5s;
  opacity: 0;
  font-family: "Lato", sans-serif;
  font-weight: 500;
}

.hero_container-text-h1 {
  display: flex;
  gap: 2rem;
  opacity: 0;
  animation: hero-from-right 2.4s forwards;
  animation-delay: 1.4s;
}

.hero_container-text-h1 h1 {
  font-size: 6.8rem;
  letter-spacing: 8px;
  color: #f7f7f7;

  font-family: "Lato", serif;

  font-weight: 500;
}

.hero_container-text p {
  font-size: 1.8rem;
  letter-spacing: 4px;
  color: #f7f7f7;
  margin-bottom: 1em;

  animation: fade-in 3s forwards;
  animation-delay: 2s;
  opacity: 0;
  font-family: "Lato", sans-serif;
  font-weight: 300;
}

.hero_container-button {
  margin-top: 4em;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 14em;
}

.hero_container-button-act_button {
  animation: fade-in 2s forwards;
  animation-delay: 1s;
  opacity: 0;
}

.hero_container-button-sub_button {
  position: absolute;
  bottom: 4rem;
  right: 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background: #fff;
  color: #555;
  border-radius: 1rem;

  /* animation: fade-in 2s forwards;
  animation-delay: 4s;
  opacity: 0; */

  margin-right: 6rem;

  &:hover {
    background: #10752b;
    color: #fff;
  }

  &:hover .hero_container-button-sub_button-arrow_right {
    transform: translateX(0.6rem);
  }
}

.hero_container-button-sub_button p {
  font-family: "Quicksand", sans-serif;
}

.hero_container-button-sub_button-arrow_right {
  padding-bottom: 0;
  transition: transform 0.2s;
  scale: 1.1;
}

.hero_container-icon_down {
  all: unset;

  color: #eee;
  width: 100px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4em;
  cursor: pointer;

  left: 50%;
  transform: translateX(-50%);

  transition: 0.5s;
  animation: icon-down 1s infinite ease-in-out alternate;
}

@keyframes icon-down {
  0% {
    margin-bottom: 0;
  }
  100% {
    margin-bottom: 1.6em;
  }
}

@keyframes hero-from-left {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
    opacity: 0.9;
  }
}

@keyframes hero-from-right {
  0% {
    transform: translateX(25%);
  }
  100% {
    transform: translateX(0);
    opacity: 0.9;
  }
}

@keyframes fade-in {
  50% {
    /* transform: scale(1.2); */
  }
  100% {
    opacity: 1;
    /* transform: scale(1); */
  }
}

@media screen and (max-width: 1020px) {
  .hero_container {
    padding-right: 0em;
    align-items: center;

    box-shadow: inset -40em 0 1000em #0000006c;
  }

  .hero_container-text {
    align-items: flex-start;
  }

  .hero_container-text h2 {
    font-size: 2em;
  }

  .hero_container-text h1 {
    font-size: 4em;
  }

  .hero_container-button {
    flex-direction: column;
    justify-content: space-between;
    gap: 0;
  }

  .hero_container-button button {
    padding: 1em 2em;
    min-width: 320px;
    margin-top: 2em;
  }
}

@media screen and (max-width: 720px) {
  .hero_container {
    position: relative;
    min-height: 100svh;

    padding-top: 12svh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    scroll-snap-align: start;

    filter: grayscale(0.4);
    box-shadow: inset 0 100rem 1000rem #00000060;
    margin: 1rem;

    background-image: url("../../assets/images/thk-hero.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .hero_container-left_background {
    flex: 1;

    position: absolute;
    left: 2rem;
    top: 2rem;
    bottom: 2rem;

    width: 45%;
    max-height: 100svh;
  }
  .hero_container-right_background {
    background-image: url("../../assets/images/1945.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    flex: 1;

    position: absolute;
    top: 2rem;
    bottom: 2rem;
    right: 2rem;

    width: 50%;
    max-height: 100svh;

    box-shadow: inset 0 100rem 1000rem #00000060;
  }

  .hero_container-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .hero_container-text h2 {
    font-size: 1.4rem;
    letter-spacing: 4px;
    color: #f7f7f7;
    margin-bottom: 1em;

    animation: hero-from-left 2s forwards;
    animation-delay: 0.5s;
    opacity: 0;
    font-family: "Lato", sans-serif;
    font-weight: 500;
  }

  .hero_container-text-h1 {
    display: flex;
    flex-direction: column;
    gap: 0;
    opacity: 0;
    animation: hero-from-right 2.4s forwards;
    animation-delay: 1.4s;
  }

  .hero_container-text-h1 h1 {
    font-size: 1.8rem;
    letter-spacing: 4px;
    color: #f7f7f7;

    font-family: "Lato", serif;

    font-weight: 500;
  }

  .hero_container-text p {
    font-size: 1.2rem;
    letter-spacing: 4px;
    color: #f7f7f7;
    margin-bottom: 1em;

    animation: fade-in 3s forwards;
    animation-delay: 2s;
    opacity: 0;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    padding: 1rem;
  }

  .hero_container-button {
    margin-top: 4em;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 14em;
  }

  .hero_container-button-act_button {
    animation: fade-in 2s forwards;
    animation-delay: 1s;
    opacity: 0;
  }

  .hero_container-button-sub_button {
    position: static;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    background: #fff;
    color: #555;
    border-radius: 1rem;

    /* animation: fade-in 2s forwards;
    animation-delay: 4s;
    opacity: 0; */

    margin-right: 0;
    font-size: 1rem;

    &:hover {
      background: #10752b;
      color: #fff;
    }

    &:hover .hero_container-button-sub_button-arrow_right {
      transform: translateX(0.6rem);
    }
  }

  .hero_container-button-sub_button p {
    font-family: "Quicksand", sans-serif;
  }

  .hero_container-button-sub_button-arrow_right {
    padding-bottom: 0;
    transition: transform 0.2s;
    scale: 1.1;
  }
}
