.js10_container {
  min-height: 100svh;
  max-height: 100svh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: linear-gradient(90deg, #e66465, #9198e5);
}

.js10_container-converter {
  background: #ffffff38;
  border-radius: 1rem;
  box-shadow: 0 0 1rem #0000006b;

  width: 50vw;
  height: 50svh;
  padding: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.js10_container-converter h1 {
  color: #008080;
}

.js10_container-converter-input_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.js10_container-converter-input_container label {
  color: #008080;
  font-size: 2rem;
}

.js10_container-converter-input_container input {
  all: unset;
  font-size: 2rem;
  padding: 1rem;
  border-radius: 1rem;
  /* border: 2px solid #0000004f; */
  border: none;
  box-shadow: inset 0 0 4px #000;
  background: #ffffff56;
  width: 100%;
  margin-left: 2rem;
}

.js10_container-converter p {
  color: #008080;
  font-size: 1.6rem;
}

#js10-error {
  color: #ec3b3b;
}

@media screen and (max-width: 600px) {
  .js10_container-converter h1 {
    font-size: 1.6rem;
  }

  .js10_container-converter {
    width: 90vw;
    height: 50svh;
    padding: 2rem;
  }

  .js10_container-converter-input_container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .js10_container-converter-input_container input {
    all: unset;
    font-size: 1.6rem;
    padding: 1rem;
    border-radius: 1rem;
    /* border: 2px solid #0000004f; */
    border: none;
    box-shadow: inset 0 0 4px #000;
    background: #ffffff56;
    width: 90%;
    margin: 0;
  }
}
