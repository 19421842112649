.bygg1footer {
  position: relative;
  /* width: 100%; */
  background: #2c515e;
  color: #fff;

  display: flex;
}

#bygg1footer-contact-form-header {
  margin-bottom: 6rem;
  font-weight: 500;
  color: #fff;
}

.bygg1footer-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding-top: 4rem;
  padding-bottom: 10rem;
}

.bygg1footer-contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0 12rem;
}

.bygg1footer-contact-form label {
  font-size: 1.6rem;
  letter-spacing: 0.2rem;
}

.bygg1footer-contact-form input {
  font-size: 1.6rem;
  letter-spacing: 0.2rem;
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 2rem;
  border: none;
  width: 100%;
}

.bygg1footer-contact-form textarea {
  font-size: 1.6rem;
  letter-spacing: 0.2rem;
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 2rem;
  border: none;
  width: 100%;
}

.bygg1footer-contact button {
  background: #fff;
  color: #2c515e;
  border-radius: 100rem;
}

.bygg1footer-social {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.bygg1footer-social-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
}

.bygg1footer-social-content-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.bygg1footer-social-content-item h1 {
  font-size: 2.2rem;
  font-weight: 300;
  color: #fff;
}

.bygg1footer-social-content-item p {
  font-size: 1.6rem;
  font-weight: 300;
  color: #fff;
}

#bygg1footer-social-copy {
  position: absolute;
  bottom: 4rem;
  left: 50%;

  font-size: 1.2rem;
  font-weight: 300;
  letter-spacing: 0.2rem;
  color: #fff;

  transform: translateX(-50%);
}

@media screen and (max-width: 560px) {
  .bygg1footer {
    flex-direction: column;
    padding-bottom: 10rem;
  }

  #bygg1footer-contact-form-header {
    font-size: 1.6rem;
    margin-bottom: 2rem;
    font-weight: 500;
    color: #fff;
  }

  .bygg1footer-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 1rem;
    padding-top: 2rem;
    padding-bottom: 4rem;
  }

  .bygg1footer-contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
  }

  .bygg1footer-contact-form label {
    font-size: 1rem;
    letter-spacing: 0rem;
  }

  .bygg1footer-contact-form input {
    font-size: 1rem;
    letter-spacing: 0rem;
    padding: 0.6rem;
    margin-bottom: 1rem;
  }

  .bygg1footer-contact-form textarea {
    font-size: 1rem;
    letter-spacing: 0rem;
    padding: 0.6rem;
    margin-bottom: 2rem;
    border-radius: 0.2rem;
  }

  .bygg1footer-contact button {
    font-size: 1rem;
    padding: 1rem 4rem;
  }

  .bygg1footer-social {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .bygg1footer-social-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .bygg1footer-social-content-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bygg1footer-social-content-item h1 {
    font-size: 1.6rem;
    font-weight: 300;
    color: #fff;
  }

  .bygg1footer-social-content-item p {
    font-size: 1rem;
    font-weight: 300;
    color: #fff;
  }

  #bygg1footer-social-copy {
    position: absolute;
    bottom: 4rem;
    left: 50%;

    font-size: 0.8rem;
    font-weight: 300;
    letter-spacing: 0.2rem;
    color: #fff;

    transform: translateX(-50%);
  }
}
