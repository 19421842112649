.samhalle3Section {
  position: relative;
  background: #f9f6ea;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8rem 0;

  /* border: 2px solid red; */
}

.samhalle3Section-text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 4rem;
  margin-left: 14rem;

  /* top: 10rem;
  left: 15rem;
  right: 40%; */

  background: #f9f6ea;
  height: 50svh;
}

.samhalle3Section-text h3 {
  color: #fbc86b;
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: uppercase;
  text-align: start;
  letter-spacing: 0.2rem;
}

.samhalle3Section-text h1 {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  color: #304251;
  font-size: 2.8rem;
  letter-spacing: 0.2rem;
  line-height: 2.8rem;
  font-weight: 300;
  text-align: start;
  padding-top: 1rem;
}

.samhalle3Section-text p {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  color: #304251;
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
  font-weight: 300;
  text-align: start;
  padding: 2rem 0;
}

.samhalle3Section-content {
  /* flex: 1; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 4rem;
  padding: 8rem 4rem;
}

.samhalle3Section-content-item {
  position: relative;
  flex: 1;
  width: 100%;

  height: 50svh;
}

.samhalle3Section-content-item-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background: #f9f6ea;
  color: #304251;
}

.samhalle3Section-content-item-text h1 {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-weight: 300;
  font-size: 1.8rem;
}
.samhalle3Section-content-item-text p {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  text-align: start;
  font-size: 1.4rem;
}

#samhalle3Section-content-item-id-1 {
  background-image: url("../../assets/images/samhalle-10.jpg");
  background-position: center;
  background-size: cover;
}

#samhalle3Section-content-item-id-2 {
  background-image: url("../../assets/images/samhalle-5.jpg");
  background-position: center;
  background-size: cover;
}

#samhalle3Section-content-item-id-3 {
  background-image: url("../../assets/images/samhalle-9.jpg");
  background-position: center;
  background-size: cover;
}

@media screen and (max-width: 560px) {
  .samhalle3Section {
    position: relative;
    background: #f9f6ea;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0;

    /* border: 2px solid red; */
  }

  .samhalle3Section-text {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 0;
    margin-left: 0;

    /* top: 10rem;
    left: 15rem;
    right: 40%; */

    background: #f9f6ea;
    height: auto;
  }

  .samhalle3Section-text h3 {
    color: #fbc86b;
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: uppercase;
    text-align: start;
    letter-spacing: 0.2rem;
    padding: 0 2rem;
  }

  .samhalle3Section-text h1 {
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    color: #304251;
    font-size: 1.6rem;
    letter-spacing: 0.2rem;
    line-height: 2.8rem;
    font-weight: 300;
    text-align: start;
    padding: 0 2rem;
  }

  .samhalle3Section-text p {
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    color: #304251;
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    font-weight: 300;
    text-align: start;
    padding: 2rem;
  }

  .samhalle3Section-content {
    /* flex: 1; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 2rem;
    padding: 2rem;
  }

  .samhalle3Section-content-item {
    position: relative;
    flex: 1;
    width: 100%;

    height: auto;
    aspect-ratio: 1/1;
  }

  .samhalle3Section-content-item-text {
    background: #f9f6ea;
    color: #304251;
  }

  .samhalle3Section-content-item-text h1 {
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    font-weight: 300;
    font-size: 1.4rem;
  }
  .samhalle3Section-content-item-text p {
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    text-align: start;
    font-size: 1.2rem;
  }

  #samhalle3Section-content-item-id-1 {
    background-image: url("../../assets/images/samhalle-10.jpg");
    background-position: center;
    background-size: cover;
  }

  #samhalle3Section-content-item-id-2 {
    background-image: url("../../assets/images/samhalle-5.jpg");
    background-position: center;
    background-size: cover;
  }

  #samhalle3Section-content-item-id-3 {
    background-image: url("../../assets/images/samhalle-9.jpg");
    background-position: center;
    background-size: cover;
  }
}
