.lil1_landing_container {
  min-height: 100svh;
  max-height: 100svh;

  background-image: url("../assets/landing-1-xxl.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.lil1_landing_container-welcome_text {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  padding: 8rem;
}

.lil1_landing_container-welcome_text-sub {
  margin: 0;
  padding: 0;

  font-size: 2.2rem;
}

.lil1_landing_container-welcome_text-main {
  margin: 0;
  padding: 0;

  font-size: 3rem;
  color: #b78727;
}

.lil1_landing_container-image {
  width: 40%;
  border-radius: 100%;
  /* margin: 2rem; */
  filter: grayscale(0.5) blur(1px);
  opacity: 0.5;

  box-shadow: 0 0 10rem #000000a9;
}

#lil1_landing_container-image-1 {
  margin-top: 60px;
}
#lil1_landing_container-image-2 {
  margin-left: 60px;
}
#lil1_landing_container-image-3 {
  margin-right: 60px;
}

@media screen and (max-width: 600px) {
  .lil1_landing_container {
    grid-template-columns: 1fr;
  }

  .lil1_landing_container-welcome_text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    padding: 0 1rem;
  }

  .lil1_landing_container-welcome_text-sub {
    font-size: 1.8rem;
  }

  .lil1_landing_container-welcome_text-main {
    font-size: 2.2rem;
  }

  #lil1_landing_container-image-1 {
    position: absolute;
    top: 3rem;
    left: 2rem;
    width: 25%;
  }
  #lil1_landing_container-image-2 {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    width: 20%;
  }
  #lil1_landing_container-image-3 {
    display: none;
  }
}
