.htk3-page_header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem 0;
}

.htk3-page_header-header {
  color: var(--color-htk-2);
  color: #333;
  font-size: 2.2rem;
  text-transform: uppercase;
}

.htk3-page_header-underline {
  min-height: 6px;
  min-width: 50%;
  background: var(--color-htk-2);
  margin-left: 2rem;
}

@media screen and (max-width: 560px) {
  .htk3-page_header {
    justify-content: center;
    align-items: center;
  }
  .htk3-page_header-header {
    color: var(--color-htk-2);
    color: #333;
    font-size: 1.4rem;
    text-align: center;
    text-transform: uppercase;
  }
  .htk3-page_header-underline {
    margin-left: 0;
  }
}
