.napra3-landing-container {
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding: 8rem 0;
}

.napra3-landing-container_text-container {
  width: 50%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.napra3-landing-container_text-container h4 {
  color: var(--color-napra);
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.6rem;
}

.napra3-landing-container_text-container h1 {
  color: #fff;

  font-size: 4rem;
  font-weight: 500;
  line-height: 5.6rem;
  letter-spacing: 0.2rem;
  width: 80%;
}

.napra3-landing-container_text-container-underline {
  height: 4px;
  background: var(--color-napra);
  width: 50%;
}

.napra3-landing-container_text-container p {
  color: #fff;
  font-size: 2rem;
  font-weight: 300;
  width: 75%;
}

.napra3-landing-container_button-container {
  display: flex;
  gap: 10rem;
  margin-top: 2rem;
}

.napra3-landing-container_button-container button {
  background: var(--color-napra);
  opacity: 0.8;
  color: #fff;
  padding: 1rem 4rem;
  font-size: 1.6rem;
  border: none;
  border-radius: 0.3rem;

  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 1;
  }
}

#napra3-landing-container_button-container_services-button {
  background: transparent;
  border: 2px solid var(--color-napra);
}

.napra3-landing-container_button-container a {
  color: #fff;
  text-decoration: none;
}

@media only screen and (max-width: 800px) {
  .napra3-landing-container {
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .napra3-landing-container_text-container {
    width: 100%;
    padding: 1rem;
  }
  .napra3-landing-container_text-container h4 {
    letter-spacing: 0.2rem;
    margin-bottom: 2.6rem;
    font-size: 1rem;
  }
  .napra3-landing-container_text-container h1 {
    font-size: 2rem;
    padding: 1rem;
    border-radius: 2rem;
    margin: 0;
    padding: 0;
    line-height: 2rem;
  }
  .napra3-landing-container_text-container p {
    font-size: 1rem;
    line-height: 1.2rem;
    margin-top: 1rem;
  }
  .napra3-landing-container_button-container {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
  }
  .napra3-landing-container_button-container button {
    padding: 1rem 2rem;
    font-size: 1.2rem;
  }
}
