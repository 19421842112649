.home-container {
    background: #fff;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #fff, orange);
    background: linear-gradient(to left, #fff, orange); 

    min-height: 50vh;
    height: 100vh;
    min-width: 100%;
    padding-top: 124px;
   
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    /* border: 2px solid black; */
    /* padding-top: 100px; */

    margin: 0;
}

.home-container_img {
    width: 50%;
}

.home-title_container {
    /* border: 1px solid white; */
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 24px 0px;
    background: #ffffff54;
    /* margin-top: 64px; */

    
}

.home-title_continer_title {
    color: #333;
    /* text-shadow: 1px 1px 2px #333; */
    font-size: 28px;
    font-weight: 700;
    margin: auto;
}

.carousel-container {
    width: 50vh;
}

.carousel-container_img {
    width: 100%;
    height: 300px;
}

@media only screen and (max-width: 800px) {
    .home-title_container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 0;
        
        /* padding-top: 64px;
        padding-bottom: 64px; */
        
    }
    
    .home-title_continer_title {
        /* color: #ddd; */
        /* text-shadow: 2px 2px 4px #000; */
        font-size: 16px;
        line-height: 16px

    }

    .carousel-container {
        width: 70%;
    }
    
    .carousel-container_img {
        width: 100%;
        height: 150px;
    }
  }