.napra4_slider {
  width: 100%;
  height: 100%;

  position: relative;
  z-index: 5;
}
.napra4_slider-image {
  object-fit: cover;
  width: 100%;
  min-height: 420px;
  max-height: 420px;
  display: block;

  border-radius: 2rem;
}
.napra4_slider-button {
  all: unset;
  display: block;
  position: absolute;
  top: 0;
  bottom: 28px;
  padding: 1rem;
  cursor: pointer;

  transition: all 0.2s ease-in-out;

  &:hover {
    background: #0000002a;
  }
}

/*all children in the container */
.napra4_slider-button > * {
  stroke: #fff;
  /* fill: #333; */
}

#napra4_slider-button_left {
  left: 0;
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
}
#napra4_slider-button_right {
  right: 0;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.napra4_slider-button_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.napra4_slider-button-dots_container {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: -1rem;
  width: 50%;
  border-radius: 2rem;
  /* background: #ffffff54; */
}

.napra4_slider-button-dots_container button {
  all: unset;
  /* background: #000; */
  margin: 0.6rem;
  /* width: 10px;
  height: 10px; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.12s;

  &:hover {
    /* transform: scale(1.1); */
    box-shadow: 0px 0px 6px #ffa500;
  }
}

.napra4_slider-button-dots_container-button {
  /* stroke: #fff; */
  /* fill: #fff; */
  transition: 0.3s;
}

@media screen and (max-width: 600px) {
  .napra4_slider-image {
    min-height: 320px;
    max-height: 320px;
  }
}
