.haninge4_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;

  /* padding: 0 2rem;
  gap: 2rem; */

  background: #4169e1;
}
