.napra4_home {
  /* min-height: 100svh; */
}

.napra4_home-wrapper {
  display: flex;
  background: #fff;

  padding: 2rem;
  gap: 4rem;
}

.napra4_home-hero {
  background-image: url("../../naprapatrehab/naprapatrehab_img/DSC06345.jpeg");
  background-position: center;
  background-size: cover;
  /* filter: grayscale(0.5); */
  min-height: 80svh;
  width: 100%;
  border-radius: 2rem;

  padding: 4rem;
  padding-left: 50%;
  overflow: hidden;
}
.napra4_home-desc {
  background: linear-gradient(to right, #00000000, #0000008e);
  padding: 2rem;
  border-radius: 0.2rem;

  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.napra4_home-desc h6 {
  color: #ffa500;
  font-size: 1.6rem;
}

.napra4_home-desc h2 {
  /* text-shadow: 0 0 4px #000; */
  color: #fff;
  font-size: 1.6rem;
}

.napra4_home-desc h1 {
  /* text-shadow: 0 0 4px #000; */
  color: #fff;
  text-align: center;
  font-size: 2.2rem;
}

.napra4_home-desc p {
  font-size: 1.6rem;
  text-align: center;
  color: #fff;
}

.napra4_home-desc-buttons button {
  all: unset;
  background: #ffa500;
  padding: 1rem 2rem;
  border-radius: 0.2rem;
  font-size: 1.6rem;

  transition: 0.5s;

  opacity: 0.8;

  &:hover {
    cursor: pointer;

    /* color: #ffa500;
    background: #fff; */
    opacity: 1;
  }
}

@media screen and (max-width: 600px) {
  .napra4_home {
    margin: 0;
    margin-bottom: 2rem;
  }

  .napra4_home-wrapper {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .napra4_home-hero {
    min-height: 50svh;
    padding: 0;
  }

  .napra4_home-desc {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .napra4_home-desc p {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }

  .napra4_home-desc h6 {
    font-size: 0.8rem;
  }

  .napra4_home-desc h2 {
    font-size: 2rem;
  }

  .napra4_home-desc h1 {
    font-size: 2rem;
  }
}
