.htk1-sidebar-container {
    background: linear-gradient(to right, #4682b4, #00000000);
    width: 5%;
    transition: 0.5s;

    z-index: 10;

    position: fixed;
    top: 0;
    min-height: 100vh;
    /* margin-left: -2rem; */

    cursor: pointer;
}

.htk-sidebar-container_links-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 75vh;
    padding-left: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    /* border: 1px solid black; */

   
    min-height: 100vh;
    transition: 0.5s;

    z-index: 10;

}

#htk1-sidebar-button {
    position: relative;
    top: 10vh;
    left: 1.8rem;

    transition: 0.5s;
}

#htk1-sidebar-button:hover, .htk1-sidebar-container:hover  {
    width: 7.5%;
    /* top: 1.8rem;
    left: 2.2rem; */
}

@media only screen and (max-width: 1020px) {

    #htk1-sidebar-button {
        position: relative;
        top: 12vh;
        left: 1.2rem;
        font-size: 1.5rem;
    }

  }
