.htk4_events {
  min-height: 100svh;
  max-height: 100svh;
  width: 100%;

  display: flex;
  /* flex-direction: column; */
  justify-content: flex-start;
  align-items: center;
  gap: 4rem;

  border: 1px solid red;
  overflow-x: auto;

  scroll-snap-type: mandatory;
  margin-left: 3rem;
}

.htk4_events-event {
  height: 50svh;
  min-width: 25vw;
  padding: 4rem;
  border: 1px solid white;
  border-radius: 4rem;

  display: flex;
  flex-direction: column;
  background: #ffffff94;
}

.htk4_events-event img {
  width: 100%;
  height: 50%;
  object-fit: cover;
  border-radius: 2rem;
}

.htk4_events-event_dots_container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 500px;
  height: 25px;
  border: 1px solid black;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
}

.htk4_events-event_dots_container-dot {
  width: 10px;
  height: 10px;
  background: #000;
  border-radius: 100px;
  cursor: pointer;

  &:hover {
    background: white;
  }
}
