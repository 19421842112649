.bygg1nav {
  position: absolute;
  width: 100%;
  height: 10rem;
  background: #ffffff80;

  display: flex;
  justify-content: space-between;
  align-items: center;

  z-index: 1;
}

.bygg1nav-img {
  flex: 1;
}
.bygg1nav ul {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex: 2;
}
.bygg1nav-link {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  cursor: pointer;

  &:hover .hover-underline {
    width: 100%;
  }
}
.bygg1nav-link li {
  color: #2c515e;
  list-style-type: none;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.4rem;
}

.hover-underline {
  width: 0;
  height: 2px;
  background: #2c515e;
  transition: 0.3s;
}

@media screen and (max-width: 560px) {
  .bygg1nav {
    height: 6rem;
  }

  .bygg1nav-img img {
    width: 100%;
  }
  .bygg1nav ul {
    flex: 3;
  }
  .bygg1nav-link li {
    font-size: 0.8rem;
    letter-spacing: 0rem;
  }

  .hover-underline {
    height: 1px;
  }
}
