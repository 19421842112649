.about-napra2-container {
    /* border: 1px solid black; */
    text-align: center;
    background: #fcf3e7;
    margin-top: 64px;
    margin-right: 64px;
    margin-bottom: 64px;
    margin-left: 0px;
}

.about-napra2-container_description {
    /* border: 1px solid black; */
    display: flex;
    flex-wrap: wrap;
    padding: 12px;
    text-align: center;
}

.about-napra2-container_description-text {
    font-family: 'League Spartan', 'sans-serif', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode';
    margin: 24px;
    padding: 24px;
    border-radius: 2px;
    color: #333;
    font-size: 32px;
}

.about-napra2-container_description-text_title {
    text-align: start;
    font-size: 22px;
    font-style: italic;
    font-weight: 700;
}

.about-napra2-container_description-text_subtitle {
    text-align: start;
    font-size: 18px;
    padding-bottom: 32px;
}

.about-napra2-container_images {
    /* border: 1px solid white; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* flex-wrap: wrap; */
    padding-top: 64px;
    margin-bottom: 44px;
}

.about-napra2-container_card {
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-napra2-container_images-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    min-width: 250px;
    border-radius: 100%;
    margin: 64px;
    /* border: 1px solid black; */
    /* box-shadow: 0px 0px 12px white; */
    
}

.about-napra2-container_images-card_description-container {
    padding: 64px;
}

.about-napra2-container_images-card_title-container {
    text-shadow: 2px 2px 2px #000;
    /* border: 1px solid black; */
    background: #00000062;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    padding: 6px 12px;
    border-radius: 100px;
    margin-right: -32px;
    margin-bottom: -12px;
}

.about-napra2-container_card-title {
    color: #fff;
    font-size: 20px;
    margin: 0;
}

.about-napra2-container_images-card_description-container p {
    font-size: 1vw;
}

.about-napra2-container_images img {
    border-radius: 25px;
    margin: 12px;

}



#napra2-about1 {
    background-image: url('../assets/img/Susanne Porträtt 1.jpeg');
    background-size: 100%;
    width: 250px;
    height: 250px;
}

#napra2-about2 {
    background-image: url('../assets/img/Line Porträtt 1.jpeg');
    background-size: 100%;
    width: 250px;
    height: 250px;
}

#napra2-about3 {
    background-image: url('../assets/img/Sofie Porträtt 2.jpeg');
    background-size: 100%;
    width: 250px;
    height: 250px;
}

@media only screen and (max-width: 600px) {

    .about_container {
        padding: 0;
    }

    .about-napra2-container_card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }

    #about_container-description_text-title {
        text-align: start;
    }

    .about-napra2-container_images-card_description-container {
        padding: 36px;
    }

    .about_container-description_text {
        padding: 0;
    }

    .about-napra2-container_images-card {
        transform: scale(0.8);
        margin: 0px;
    }

    .about-napra2-container_description-text {
        padding: 0px;
    }
  }

  @media only screen and (max-width: 500px) {

    .about-napra2-container_images-card_description-container p {
        font-size: 16px;
        line-height: 16px;
    }
    

  }