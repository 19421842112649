.bygg2section2 {
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4rem;
  padding: 0 12rem;

  /* border: 2px solid red; */
  padding: 16rem;
  padding-bottom: 4rem;
  padding-top: 4rem;
}

.bygg2section2-content {
  display: flex;

  /* background: #f87104; */
}

.bygg2section2 h1 {
  /* color: #f87104; */
  color: #333;
  font-size: 2.6rem;
  font-weight: 300;
  text-align: start;

  animation: fade-in-text;
  animation-timeline: view();
}

.bygg2section2-content-image {
  min-height: 65svh;
  max-height: 65svh;
  flex: 1;
}

.bygg2section2-content-text {
  flex: 2;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 2rem;
}
.bygg2section2-content-text h1 {
  color: #fff;
  text-align: start;
  font-size: 2.2rem;
  padding-bottom: 1rem;
}
.bygg2section2-content-text p {
  color: #fff;
  text-align: start;
  font-size: 1.4rem;
  font-weight: 300;
  padding-top: 2rem;
}

.bygg2section2-content-text button {
  color: #f87104;
  background: #fff;
  border-radius: 100rem;
  margin-top: 4rem;
}

#bygg2section2-content-1 {
  background-image: url("../../assets/images/bygg-8.jpg");
  background-position: center;
  background-size: cover;
}

#bygg2section2-content-2 {
  background-image: url("../../assets/images/bygg-3.jpg");
  background-position: center;
  background-size: cover;
}

#bygg2section2-content-3 {
  background-image: url("../../assets/images/bygg-6.jpg");
  background-position: center;
  background-size: cover;
}

#bygg2section2-content-4 {
  background-image: url("../../assets/images/bygg-5.jpg");
  background-position: center;
  background-size: cover;
}

@media screen and (max-width: 560px) {
  .bygg2section2 {
    align-items: flex-start;
    justify-content: center;
    align-items: center;
    gap: 0;
    padding: 0;
  }

  .bygg2section2 h1 {
    color: #333;
    padding-top: 4rem;
    font-size: 1.6rem;
    font-weight: 300;
    text-align: center;
    padding-bottom: 4rem;
  }

  .bygg2section2-content {
    flex-direction: column;
  }

  .bygg2section2-content-image {
    min-height: 75svh;
    max-height: 75svh;
    flex: 2;
  }

  .bygg2section2-content-text {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    padding: 1rem;
  }
  .bygg2section2-content-text h1 {
    color: #fff;
    text-align: start;
    font-size: 1.6rem;
  }
  .bygg2section2-content-text p {
    color: #fff;
    text-align: center;
    font-size: 1rem;
    padding: 1rem 0;
    padding-top: 2rem;
  }

  .bygg2section2-content-text button {
    padding: 1rem 4rem;
    font-size: 1rem;
    margin-top: 0;
    margin: 2rem 0;
  }
}
