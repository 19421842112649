.menu {
	/* border: 1px solid black; */
}

.menu__links {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;

	text-align: end;
	background: var(--color-theme);
	padding: 2rem;
	/* margin: 1rem; */
	position: absolute;
	top: 20px;
	right: -20px;
	min-width: 280px;
	border-radius: 5px;
	box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.menu__links-text {
	color: #ccc;
	cursor: pointer;
	text-decoration: none;
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 0.2rem;

	/* transition-property: color;
	transition-duration: 4s;
	transition-delay: 2s; */
	transition: color 0.5s;

	/* border: 1px solid salmon; */
}

.menu__links-text:hover {
	color: #fff;
}
