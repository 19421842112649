.napra3-footer-container {
  /* border: 1px solid black; */
  padding: 6rem;
  padding-bottom: 2rem;

  background-image: url("../assets/img/footer.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset -100vw 0px 100px #00000079;
}

.napra3-footer-container_info-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;

  color: #fff;
}

.napra3-footer-container_info-container_contact-us-container {
  /* border: 1px solid black; */
  text-align: center;
  width: 100%;
}
.napra3-footer-container_info-container_contact-us-container p {
  color: #fff;
}

.napra3-footer-container_info-container_contact-us-container i {
  margin: 1rem;
}

.napra3-footer-container_info-container_contact-us-container_icon-container {
}

.napra3-footer-container_info-container_contact-container {
  /* border: 1px solid black; */
  text-align: center;
  width: 100%;
}

.napra3-footer-container_info-container_contact-us-container_contact-container_title {
  font-weight: 700;
  font-size: 1.4rem;
  color: #fff;
}

.icon_container-title {
  color: #fff;
}

.napra3-footer-container_copyright p {
  padding-top: 2rem;
  text-align: center;
  color: #fff;
}

@media (max-width: 800px) {
  .napra3-footer-container {
    padding: 2rem;
  }

  .napra3-footer-container_info-container_contact-us-container {
    padding-bottom: 6rem;
  }

  .napra3-footer-container_info-container {
    flex-direction: column;
  }

  .napra3-footer-container_info-container_contact-us-container_contact-container {
    display: flex;
    justify-content: space-between;
    /* border: 1px solid black; */
    padding: 1rem;
    padding-bottom: 0rem;
  }

  .napra3-footer-container_info-container_contact-us-container_contact-container
    p {
    padding: 0;
    margin: 0;
  }
}
