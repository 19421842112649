.experience-content {
  margin-bottom: 32px;
}

.experience-content h5 {
  color: #333;
  text-align: start;
}

.experience-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.experience-year {
  font-size: 16px;
  color: #333;
}

.job-title {
  font-weight: bold;
  font-size: 22px;
  color: #333;
}

.experience-description {
  color: #777;
  font-weight: bold;
  text-align: start;
}
