.js6_container * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.js6_container {
  min-height: 100svh;
  max-height: 100svh;

  padding: 4rem 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #eee;
}

.js6_container h1 {
  font-size: 3rem;
}

.js6_container-dice_container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 4rem;
  padding: 4rem 0;
}

.js6_container-dice_container-dice {
  width: 100px;
  height: 100px;
  border: 4px solid #000;

  display: flex;
  /* flex-direction: column; */
  justify-content: space-evenly;
  align-items: center;

  flex-wrap: wrap;
}

.animation-roll {
  animation: roll 1s forwards;
}

@keyframes roll {
  25% {
    transform: rotateY(-360deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.js6_container-dice_container-dice-dot {
  width: 12px;
  height: 12px;
  background: #000;
  border-radius: 100px;
  margin: 0.7rem;
}

.js6_container-dice_container button {
  background: #4682b4;
  color: #fff;
  border: none;
  border-radius: 1rem;
  padding: 2rem 4rem;
  font-size: 1.6rem;
  cursor: pointer;
  opacity: 0.8;

  transition: 0.3s;

  &:hover {
    opacity: 1;
  }
}
.js6_container-dice_container p {
  font-size: 1.6rem;
}

.js6_container-rollist_container {
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: safe center;
  align-items: center;
}

.js6_container-rollist_container-item {
  background: #ddd;
  margin: 1rem;
  width: 90%;
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 2px 10px #aaa;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.js6_container-rollist_container-item p {
  font-size: 1.6rem;
}

@media screen and (max-width: 600px) {
  .js6_container {
    padding: 2rem 0;
  }
  .js6_container h1 {
    font-size: 1.6rem;
  }

  .js6_container-dice_container button {
    padding: 1rem 2rem;
    font-size: 1rem;
  }

  .js6_container-dice_container-dice {
    width: 70px;
    height: 70px;
  }

  .js6_container-dice_container-dice-dot {
    width: 6px;
    height: 6px;
    margin: 0.6rem;
  }

  .js6_container-rollist_container-item {
    margin: 0.6rem 1rem;
    padding: 1rem;
    border-radius: 0.2rem;
  }
}
