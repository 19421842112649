.napra3-aboutus-container {
  /* padding: 6rem 12rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100svh;
  /* background: linear-gradient(to right top, #fff 25%, var(--color-napra)); */
  /* border-top: 2px solid #999; */

  /* background: linear-gradient(to bottom, #fff 16vh, #f5a314a4); */
  gap: 8rem;
  padding: 4rem 15%;
  padding-top: 8rem;
}

.napra3-aboutus-container h1 {
  /* font-style: italic; */
  color: #333;
  font-size: 2.2rem;
}

.napra3-aboutus-container p {
  font-size: 1.6rem;
  color: #333;
  padding: 2rem;
  padding-bottom: 0rem;
}

.napra3-aboutus-container_img-container {
  display: flex;
  justify-content: flex-end;
  /* align-items: flex-end; */

  width: 100%;
  height: 100%;
}

.napra3-aboutus-container_img-container-img {
  width: 100%;
  height: 100%;
  /* aspect-ratio: 1/2; */
  background-image: url("../../naprapatrehab/naprapatrehab_img/DSC06606.jpeg");
  background-size: cover;
  background-position: center;
  /* box-shadow: inset -150px 0 100px #ffffff4b; */
}

.napra3-aboutus-container_desc-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.napra3-aboutus-container_desc-container-header {
  display: flex;
  align-items: center;
  width: 100%;
}
.napra3-aboutus-container_desc-container-header h1 {
  min-width: 200px;
  font-size: 1.6rem;
  font-weight: 300;
  letter-spacing: 0.4rem;
  padding-top: 5px;
}
.napra3-aboutus-container_desc-container-header-line {
  height: 16px;
  width: 100%;
  background: var(--color-napra);
}

.napra3-aboutus-container_desc-container-highlight {
  padding: 2rem;
  padding-top: 4rem;
}

.napra3-aboutus-container_desc-container-highlight h1 {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  /* color: #000; */
  font-size: 5.2rem;
  /* font-weight: 900; */
  line-height: 5rem;
  /* transform: translateX(-25%); */
  text-align: start;
}

.napra3-aboutus-container_desc-container p {
  font-size: 2.2rem;
  font-weight: 300;
  text-align: start;
  letter-spacing: 0.2rem;
}

@media only screen and (max-width: 800px) {
  .napra3-aboutus-container {
    /* padding: 6rem 12rem; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100svh;
    /* background: linear-gradient(to right top, #fff 25%, var(--color-napra)); */
    /* border-top: 2px solid #999; */

    /* background: linear-gradient(to bottom, #fff 16vh, #f5a314a4); */
    gap: 0rem;
    padding: 1rem;
  }

  .napra3-aboutus-container h1 {
    /* font-style: italic; */
    color: #333;
    font-size: 2.2rem;
  }

  .napra3-aboutus-container p {
    font-size: 1.6rem;
    color: #333;
    padding: 0;
  }

  .napra3-aboutus-container_img-container {
    display: flex;
    justify-content: flex-end;
    /* align-items: flex-end; */

    width: 100%;
    height: 100%;
  }

  .napra3-aboutus-container_img-container-img {
    width: 100%;
    height: 100%;
    /* aspect-ratio: 1/2; */
    background-image: url("../../naprapatrehab/naprapatrehab_img/DSC06606.jpeg");
    background-size: cover;
    background-position: center;
    /* box-shadow: inset -150px 0 100px #ffffff4b; */
  }

  .napra3-aboutus-container_desc-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .napra3-aboutus-container_desc-container-header {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .napra3-aboutus-container_desc-container-header h1 {
    min-width: 150px;
    font-size: 1.2rem;
    font-weight: 300;
    letter-spacing: 0.4rem;
    padding-top: 5px;
  }
  .napra3-aboutus-container_desc-container-header-line {
    height: 16px;
    width: 100%;
    background: var(--color-napra);
  }

  .napra3-aboutus-container_desc-container-highlight {
    padding: 0rem;
    padding-top: 4rem;
  }

  .napra3-aboutus-container_desc-container-highlight h1 {
    font-size: 1.6rem;
    line-height: 1.6rem;
    text-align: start;
    padding-bottom: 1rem;
  }

  .napra3-aboutus-container_desc-container p {
    font-size: 1.2rem;
    font-weight: 300;
    text-align: start;
    letter-spacing: 0.1rem;
  }
}
