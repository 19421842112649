.energi1section3 {
  background-image: url("../../assets/images/sol-5.jpg");
  background-size: cover;
  background-position: center;

  width: 100%;
  min-height: 60svh;
  max-height: 60svh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 10rem 0;
  box-shadow: inset 0 100rem 100rem #0000007a;
}

.energi1section3 h1 {
  color: #fff;
  text-align: center;
  font-size: 2.4rem;
  font-weight: 500;
}
.energi1section3 p {
  color: #fff;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 400;
  width: 80%;
}

.energi1section3 button {
  border-radius: 10rem;
  height: 4rem;
  background: #ffffff42;
  border: 1px solid #fff;
  box-shadow: 0 0 0.6rem #ffffffa4;
  font-size: 1.6rem;
  text-transform: uppercase;
  margin-top: 2rem;

  &:hover {
    color: #0c0f27;
    background: #ffffff9f;
    box-shadow: 0 0 0.8rem #ffab11a4;
  }
}

@media screen and (max-width: 560px) {
  .energi1section3 {
    background-image: url("../../assets/images/house-area-2.jpg");

    width: 100%;
    min-height: 60svh;
    max-height: 100svh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 0;
    padding: 2rem 1rem;
  }

  .energi1section3 h1 {
    text-align: center;
    font-size: 1.6rem;
    font-weight: 500;
  }
  .energi1section3 p {
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    width: 100%;
    padding: 2rem 0;
  }

  .energi1section3 button {
    padding: 1rem 4rem;
    font-size: 1rem;
  }
}
