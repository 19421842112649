.htk5_secbar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(75deg, #ffffff 50%, #10752bf3 50%);

  opacity: 0;
  transform: translate(100%, -100%);
  transition: 0.6s ease-in-out;
  overflow: hidden;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.htk5_secbar-openSec {
  opacity: 1;
  transform: translateX(0);
}

.htk5_secbar-img_container {
  flex: 1;
}
.htk5_secbar-img_container img {
  width: 300px;
  object-fit: cover;
}
.htk5_secbar-link_container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 80%;
  width: 50%;
}

.htk5_secbar-link_container p {
  color: #fff;
  font-size: 1.6rem;
}

@media screen and (max-width: 720px) {
  .htk5_secbar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(75deg, #ffffff 50%, #10752bf3 50%);

    opacity: 0;
    transform: translate(100%, -100%);
    transition: 0.6s ease-in-out;
    overflow: hidden;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .htk5_secbar-openSec {
    opacity: 1;
    transform: translateX(0);
  }

  .htk5_secbar-img_container {
    flex: 1;
  }
  .htk5_secbar-img_container img {
    width: 150px;
    object-fit: cover;
  }
  .htk5_secbar-link_container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 80%;
    width: 50%;
  }

  .htk5_secbar-link_container p {
    color: #fff;
    font-size: 1.4rem;
  }
}
