.homebutton-container {
    position: fixed;
    bottom: 50px;
    right: 50px;
    border-radius: 100%;
    background: #fff;
    width: 80px;
    height: 80px;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: 22px;
    font-weight: 700;
    color: #aaa;
    opacity: 0.6;
    /* border: 1px solid #333; */
    text-decoration: none;
}

.homebutton-container:hover {
    opacity: 1;
    color: orange;
}

@media only screen and (max-width: 760px) {

    .homebutton-container {
        position: fixed;
        bottom: 20px;
        right: 20px;

        width: 60px;
        height: 60px;
    }

  }