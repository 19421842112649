.htk2_tennisskolan_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    min-height: 50vh;
    max-height: 50vh;
    padding-bottom: 2rem;

    width: 100%;
}

.htk2_tennisskolan-link_container span {
    cursor: pointer;
    text-transform: uppercase;
    font-size: 1.2rem;
    margin-right: 1rem;
    transition: 0.3s;
    padding: 1rem;
    border-radius: 4px;
}

.htk2_tennisskolan-link_container span:hover {
    background: #00000046;
}

@media only screen and (max-width: 460px) {
    .htk2_tennisskolan-link_container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .htk2_tennisskolan-link_container span {
        font-size: 0.8rem;
    }
}