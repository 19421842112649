.news-container {
  margin: 8rem auto;
  padding: 20px;
  max-width: 800px;
  background-color: #10752b;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.news-title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #fff;
}

.news-item {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;

  display: flex;
  justify-content: center;
  align-items: center;

  gap: 4rem;
}

.news-item-img {
  width: 75%;
}
.news-item-img img {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 1rem;
}

.news-date {
  font-size: 14px;
  color: #fff;
  margin-bottom: 10px;
}

.news-item h2 {
  font-size: 20px;
  margin: 0;
  color: #fff;
}

.news-item p {
  font-size: 16px;
  line-height: 1.6;
  color: #fff;
}

.news-link {
  display: inline-block;
  margin-top: 10px;
  font-size: 16px;
  color: #f7f7f7;
  text-decoration: none;
  font-weight: bold;
}

.news-link:hover {
  text-decoration: underline;
  color: #f7f7f7;
}

@media screen and (max-width: 768px) {
  .news-item {
    gap: 2rem;

    flex-direction: column;
  }

  .news-item-img {
    width: 100%;
  }
  .news-item-img img {
    width: 100%;
    /* aspect-ratio: 4/3; */
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 0.4rem;
  }
}
