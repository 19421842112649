

.navbar {
    position: absolute;
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(1, 1, 1, 0.2);
    box-shadow: 2px 2px 2px #0000001c;
}

.ul_container {
    list-style: none;
   
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-width: 75%;
    font-weight: 500;
    /* text-shadow: 1px 1px 2px black; */
    /* border: 1px solid white; */
   
}

.link-item {
    text-decoration: none;
    max-width: 10%;
    font-family: 'League Spartan', 'sans-serif', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode';
    color: #fff;
    font-size: 18px;
    
}

.link-item:hover {
    cursor: pointer;
    color: #ddd;
    /* transform: scale(1.1); */
}

@media only screen and (max-width: 760px) {

    .navbar {
        position: absolute;
        min-height: 32px;
        padding-bottom: 48px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* overflow: hidden; */
    }

    .ul_container {
        min-width: 90%;
        margin-top: 12px;
        padding: 0px;
        /* border: 1px solid white; */
    }

    .link-item {
       max-width: 100%;
       
    }

    .navbar_logo {
        visibility: hidden;
    }

  }

  @media only screen and (max-width: 600px) {

     .navbar {
        position: absolute;
        max-height: 50px;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 50px;
        /* border: 1px solid red; */
    }

    .ul_container {

        width: auto 100%;
        
        
        /* border: 1px solid white; */
    }

    .link-item {
        /* visibility: hidden; */
        display: flex;
        
        flex-wrap: wrap;
        font-size: 12px;
        font-weight: 700;
        padding-bottom: 26px;
     }

  }