.htk2_container {
    /* border: 2px solid black; */
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
    background-image: url('../haningetk1/assets/img/start1_notitle.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.htk2_container-landing_container {
    display: flex;
    margin-right: 2rem;
    margin-left: 2rem;
    gap: 2rem;
    justify-content: flex-start;
    align-items: flex-start;

    /* background-color: antiquewhite; */
    
    /* flex-direction: column; */
    /* max-height: 50vh; */
    /* min-height: 80vh; */
}

.htk2_landing_container {
    /* border: 1px solid white; */
    /* background: #ffffffc5; */
    background: #00000073;
    
    padding: 2rem;

    color: #fff;
    border-radius: 4px;
    /* width: 50%; */
    flex: 2;

    min-height: 50vh;
    max-height: 50vh;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    overflow-y: scroll;
    width: 100%;
}

.htk2_landing_container h1 {
 font-size: 2.8rem;
}

.htk2_landing_container h3 {
    font-size: 1.2rem;
    line-height: 1.4rem;
}

@media only screen and (max-width: 920px) {
    .htk2_container {
        max-height: 200vh;
    }
    .htk2_container-landing_container {
        margin: 2rem;
        gap: 2rem;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        /* max-height: 50vh; */
        /* min-height: 80vh; */
    }
}

@media only screen and (max-height: 600px) {
    .htk2_container {
        max-height: 200vh;
    }

    .htk2_landing_container {
        flex: 1;
        max-height: 30vh;
     }
 
     .htk2_landing_container h1 {
         font-size: 1.4rem;
         margin-top: 0;
         margin-bottom: 0.2rem;
     }
}

@media only screen and (max-width: 460px) {

    .htk2_landing_container {
        padding: 0.6rem;
    }
    .htk2_landing_container h3 {
        font-size: 0.6rem;
        line-height: 0.8rem;
        margin-top: 0.6rem;
        margin-bottom: 0;
    }
}

