.bygg2hero {
  position: relative;
  min-height: 100svh;
  max-height: 100svh;
  width: 100%;
  background-image: url("../../assets/images/hero-1.jpg");
  background-size: cover;
  background-position: bottom;

  box-shadow: inset 0 100rem 100rem #00000040;

  display: flex;
  justify-content: center;

  padding: 8rem 0;
}

.bygg2hero-text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;

  width: 75%;
}

.bygg2hero-text h1 {
  color: #ffffffd5;
  font-size: 3.2rem;
  font-weight: 300;
  padding-top: 4rem;

  opacity: 0;
  animation: 2s fade forwards;
}
.bygg2hero-text p {
  width: 50%;
  color: #ffffffd5;
  text-align: start;
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.6rem;
  letter-spacing: 0.1rem;
  padding-bottom: 4rem;

  opacity: 0;
  animation: 2s fade forwards;
  animation-delay: 1s;
}

@keyframes fade {
  from {
    opacity: 0;
    /* transform: translateX(-100%); */
  }
  to {
    opacity: 1;
    /* transform: translateX(0); */
  }
}

.bygg2hero-icons {
  display: flex;
  color: #fff;

  /* transform: scale(2); */
  /* gap: 10rem; */
  /* background: #ffffff80; */
  gap: 4rem;
  padding-right: 15rem;
}

.bygg2hero-cards {
  display: flex;
  gap: 4rem;
  width: 100%;
  padding: 4rem;

  position: absolute;
  bottom: 0;
  transform: translateY(58%);
}

.bygg2hero-card {
  /* background: #ffffffc9; */
  background: #00000080;
  /* border: 1px solid black; */
  /* box-shadow: 0.4rem 0.4rem 0.8rem #ffffff33; */
  aspect-ratio: 3/2;

  flex: 1;

  /* border-bottom-right-radius: 6rem; */
  border-radius: 4rem;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  overflow: hidden;
}

.bygg2hero-card h1 {
  color: #fff;
  font-size: 2rem;
}

.bygg2hero-card p {
  color: #fff;
  font-size: 1.6rem;
}

.bygg2hero-icon {
  color: #fff;
  background: #f87104;
  border-radius: 100rem;
  padding: 1.2rem;
  width: 80px;
  height: 80px;
}

.bygg2hero-card-underline {
  width: 50%;
  height: 3px;
  background: #f87104;
}

@media screen and (max-width: 560px) {
  .bygg2hero {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    padding: 0;
  }

  .bygg2hero-text {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    padding-top: 6rem;

    flex: 1;

    width: 90%;
  }

  .bygg2hero-text h1 {
    color: #fff;
    font-size: 1.6rem;
    font-weight: 300;
    padding: 0;
  }
  .bygg2hero-text p {
    width: 100%;
    color: #fff;
    font-size: 1rem;
    font-weight: 300;
    padding-bottom: 0;
    text-align: center;
  }

  .bygg2hero-actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* padding: 0 4rem; */

    z-index: 1;
  }

  .bygg2hero-action {
    padding-left: 0;
  }

  .bygg2hero-action button {
    font-size: 1rem;
    padding: 1rem 2rem;
    margin-bottom: 2rem;
  }

  .bygg2hero-icons {
    display: flex;
    color: #fff;

    /* transform: scale(2); */
    /* gap: 10rem; */
    /* background: #ffffff80; */
    gap: 3rem;
    padding-right: 0;
  }

  .bygg2hero-icon {
    background: #2c515ede;
    border-radius: 100rem;
    padding: 1rem;
    width: 60px;
    height: 60px;
  }

  .bygg2hero-cards {
    display: none;
  }
}
